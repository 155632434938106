@import "../../styles/helpers/text.scss";

.backgroundSpace {
  @extend .unselectable;
  width: 100%;
  background-color: #f1f1f1;
  background-image: url("/images/index_background.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  opacity: 1;
  display: block;

  .forReactSnap {
    display: none;
  }

  .additionalFilterAnchor {
    cursor: pointer;
    position: relative;
    display: inline-block;
    color: #ffffff;
    font-size: 18px;
    line-height: 28px;
    font-weight: 400;
    margin: 0;
    padding-left: 22px;
    background-image: url("/images/icon-settings-white.svg");
    background-repeat: no-repeat;
    background-position: left center;
  }

  .optionIcon {
    position: relative;
    font-size: 14px;
    margin-right: 1px;

    &.optionIcon--topMargin {
      margin-top: 1.5px;
      font-size: 16px;
      margin-right: 3px;
    }

    &.optionIcon--widthTenPx {
      width: 10px;
    }

    &.optionIcon--marginTopOnePx {
      width: 10px;
    }
  }

  .filtersContainer__row--mobileSubmit {
    display: none;
  }

  .searchIconInline,
  .searchIconInlineWithoutText {
    background-image: url("/images/icon-search-on-button.svg");
    background-repeat: no-repeat;
    background-position: left center;
    background-size: 14px auto;
    display: inline-block;
    padding-left: 22px;
  }

  .searchIconInlineWithoutText {
    padding-left: 0;
    display: block;
    width: 16px;
    min-width: 0;
    height: 16px;
    margin: 0 auto;
  }

  .fieldElements {
    display: flex;
    width: 100%;

    .fieldElement {
      display: inline-block;

      .inputWrapper {
        display: block;
        padding: 8px 13px 9.5px 13px;
        border: 1px solid #e0e0e0;
        border-radius: 4px;
        background-color: #ffffff;
        overflow: hidden;
        position: relative;
        @extend .selectable;

        .akaLabel {
          position: absolute;
          pointer-events: none;
          font-size: 11px;
          margin: 4px 0 0 0px;
          color: #767676;
          line-height: 13px;
          z-index: 1;
          font-weight: 100;
          max-width: calc(100% - 24px);
          @extend .truncateText;
        }

        input[type="text"] {
          margin-top: 18px;
          border: 0;
          display: block;
          width: 100%;
          padding: 0;
          font-size: 15px;

          &:focus {
            outline: none;
          }

          &::placeholder {
            color: #cbcace;
            font-weight: 300;
            opacity: 1;
          }

          &:-ms-input-placeholder {
            color: #cbcace;
            font-weight: 300;
          }

          &::-ms-input-placeholder {
            color: #cbcace;
            font-weight: 300;
          }
        }

        .searchByNameInput {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          gap: 8px;
        }

        .clearInput {
          border: none;
          background-color: transparent;
          cursor: pointer;
          color: #e35a2f;
          font-size: 100%;
        }
      }

      .dropDownListSuggestContainer
        .react-select__control.react-select__control--is-focused,
      .datepickerContainer
        .react-datepicker-component
        .react-datepicker-input.is-open {
        border-color: #e0e0e0 !important;
      }

      &.fieldElement--location {
        width: 100%;
      }

      &.fieldElement--date {
        width: 194px;

        .datepickerContainer
          .react-datepicker-component
          .react-datepicker-input {
          border-right: 0;
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
        }
      }

      &.fieldElement--hour {
        width: 130px;

        .dropDownListSuggestContainer .react-select__control {
          border-radius: 0;
          border-right: 0;
        }
      }

      &.fieldElement--persons {
        width: 160px;

        .dropDownListSuggestContainer .react-select__control {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
        }
      }

      &.fieldElement--submit {
        padding-left: 14px;

        .MSButton {
          padding: 18.75px 40px 18.75px 40px;
          min-width: 190px;

          &.MSButton--onlyIcon {
            padding: 18.75px 18.5px 18.75px 19.5px;
            min-width: 55px;
          }
        }
      }

      &.fieldElement--restaurantType,
      &.fieldElement--rating,
      &.fieldElement--priceLevel,
      &.fieldElement--searchByName {
        width: 100%;
        margin-right: 18px;
      }

      &.fieldElement--searchByName {
        margin-right: 0;
      }

      &.fieldElement--rating {
        .dropDownListSuggestContainer {
          .react-select__control .react-select__single-value,
          .react-select__menu {
            color: #fd6a3b;
          }
        }
      }

      &.fieldElement--hidden {
        display: none !important;
      }
    }
  }

  .backgroundSpace__inside {
    width: 933px;
    max-width: calc(100% - 110px);
    padding: 0 55px;
    margin: 0 auto;
  }

  h1 {
    padding-top: 50px;
    text-align: center;
    font-size: 22px;
    color: #000;
    line-height: 28px;
    font-weight: lighter;
    margin: 0;

    strong {
      font-weight: 500;
    }
  }

  .filtersContainer {
    transition: all 0.2s ease-in;
    display: block;
    padding-top: 50px;

    .filtersContainer__firstColumn,
    .filtersContainer__secondColumn,
    .filtersContainer__row {
      vertical-align: top;
      height: 85px;
      background-color: #ffffff;
      border-radius: 3px;
      display: inline-block;
      padding: 15px;
      box-sizing: border-box;
    }

    .filtersContainer__firstColumn {
      width: 237px;
    }

    .filtersContainer__secondColumn {
      width: calc(100% - 252px);
      margin-left: 15px;
    }

    .filtersContainer__row {
      transition:
        overflow 0.2s ease-in,
        max-height 0.2s ease-in,
        margin-top 0.2s ease-in,
        opacity 0.2s ease-in,
        padding 0.2s ease-in;
      width: 100%;
      margin-top: 0;
      height: 85px;
      max-height: 0;
      overflow: hidden;
      opacity: 0;
      padding-top: 0;
      padding-bottom: 0;
    }
  }

  .additionalFiltersContainer {
    transition: all 0.2s ease-in;
    margin: 25px 0 0 0;
    padding-bottom: 44px;
    text-align: center;

    &.additionalFiltersContainer--moreTopMargin {
      margin-top: 25px;
      padding-bottom: 25px;
    }
  }

  &.backgroundSpace--additionalFilters {
    height: auto;

    .filtersContainer {
      .filtersContainer__row {
        margin-top: 15px;
        max-height: 85px;
        opacity: 1;
        padding: 15px;
        overflow: visible;
      }
    }
  }
}

@media only screen and (max-width: 990px) {
  .backgroundSpace {
    h1 {
      padding-top: 30px;
    }
    .filtersContainer {
      padding-top: 30px;
    }
    .additionalFiltersContainer {
      margin-top: 8px;
      padding-bottom: 30px;
    }

    .backgroundSpace__inside {
      max-width: calc(100% - 40px);
      padding: 0 20px;

      .fieldElements {
        .fieldElement {
          &.fieldElement--date {
            width: 252px;
          }
          &.fieldElement--hour {
            width: 170px;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 890px) {
  .backgroundSpace .filtersContainer {
    .filtersContainer__firstColumn {
      width: 187px;
    }
    .filtersContainer__secondColumn {
      width: calc(100% - 202px);
    }
  }
}

@media only screen and (max-width: 732px) {
  .backgroundSpace {
    background-image: none;
    background-color: #061c2f;

    .additionalFilterAnchor {
      display: inline-block;
      font-size: 14px;
    }

    .backgroundSpace__inside .fieldElements .fieldElement {
      &.fieldElement--date {
        width: 50% !important;
      }

      &.fieldElement--hour,
      &.fieldElement--persons {
        width: 25% !important;
      }
    }

    .filtersContainer__row--mobileSubmit {
      display: block;
      text-align: center;
      margin-top: -20px;
      margin-bottom: 25px;

      .MSButton {
        width: 100%;
      }
    }

    .additionalFiltersContainer {
      padding-bottom: 25px;
    }

    h1 {
      font-size: 18px;
      line-height: 24px;
      color: #ffffff;
    }

    .fieldElements .fieldElement.fieldElement--submit {
      display: none;
    }

    .filtersContainer {
      .filtersContainer__firstColumn,
      .filtersContainer__secondColumn,
      .filtersContainer__row {
        width: 100%;
        margin-left: 0;
        padding: 0 !important;
        background-color: transparent;
        height: auto;
        margin-bottom: 15px;
      }

      .filtersContainer__row {
        &.filtersContainer__row--additional {
          margin: 0 !important;

          .fieldElements {
            display: block !important;
            position: relative !important;

            & > .fieldElement {
              margin-bottom: 15px !important;
              margin-right: 7.5px !important;
              display: inline-block !important;
              width: calc(50% - 7.5px) !important;
              vertical-align: top !important;

              &:nth-child(even) {
                margin-right: 0 !important;
                margin-left: 7.5px !important;
              }

              &.fieldElement--date {
                width: 60% !important;
              }

              &.fieldElement--hidden {
                display: none !important;
              }
            }
          }
        }
      }
    }

    &.backgroundSpace--additionalFilters {
      .filtersContainer {
        .filtersContainer__row {
          margin: 0;

          &.filtersContainer__row--additional {
            margin-bottom: 0 !important;
            max-height: none !important;
          }
        }
        .filtersContainer__row--mobileSubmit {
          margin-top: 0;
        }
      }
    }

    .additionalFiltersContainer {
      margin-top: 0;

      &.additionalFiltersContainer--moreTopMargin {
        margin-top: 0;
        padding-top: 0;
      }
    }
  }
}

@media only screen and (max-width: 570px) {
  .backgroundSpace {
    .backgroundSpace__inside .fieldElements .fieldElement {
      &.fieldElement--date {
        width: 43% !important;
      }

      &.fieldElement--hour {
        width: 34% !important;
      }

      &.fieldElement--persons {
        width: 23% !important;
      }
    }

    .filtersContainer {
      .filtersContainer__row {
        &.filtersContainer__row--additional {
          .fieldElements {
            & > .fieldElement {
              margin-bottom: 15px !important;
              margin-right: 0 !important;
              display: block !important;
              width: 100% !important;

              &:nth-child(even) {
                margin-right: 0 !important;
                margin-left: 0 !important;
              }
            }
          }
        }
      }
    }

    &.backgroundSpace--additionalFilters {
      //
    }
  }
}

@media only screen and (max-width: 380px) {
  .backgroundSpace
    .fieldElements
    .fieldElement
    .inputWrapper
    input[type="text"] {
    font-size: 14px;
  }
}
