@import "./loader.scss";

.noImage {
  display: block;
  width: 100%;
  height: 120px;
  background: {
    color: #fafdff;
    image: url("/images/no-image.svg");
    repeat: no-repeat;
    position: center;
    size: 72px auto;
  }
  opacity: 0.3;
}
