@import "../../styles/helpers/text.scss";

.searchMapResultItem {
  max-width: 780px;
  max-height: none;

  .restaurantLine {
    background-color: #ffffff;
    padding: 5px 0 5px 5px;
    display: flex;

    .categoryName {
      color: #556dff;
      font-size: 11px;
      line-height: 10px;
      font-weight: 500;
      margin: 0 0 12px 0;
      text-transform: uppercase;
    }

    .restaurantName {
      display: block;
      color: #061c2f;
      font-size: 17px;
      line-height: 20px;
      font-weight: 500;
      margin: 0 0 10px 0;
      text-decoration: none;
      padding-right: 90px;

      a {
        text-decoration: none;
        color: #061c2f;
      }

      .ratingStars {
        display: inline-block;
        margin-left: 10px;
      }
    }

    .locationValue {
      vertical-align: top;
      display: inline-block;
      color: #061c2f;
      font-size: 11px;
      line-height: 13px;
      font-weight: 100;
      margin: 0;
      padding-left: 17px;
      background: {
        image: url("/images/icon-location.svg");
        repeat: no-repeat;
      }
      padding-right: 20px;
    }

    .personsDiv {
      margin-bottom: 0px;
      margin-top: 5px;
    }

    .personsValue {
      display: inline-block;
      color: #061c2f;
      font-size: 11px;
      line-height: 13px;
      font-weight: 100;
      margin: 0;
      margin-left: -2px;
      padding-left: 19px;
      background: {
        image: url("/images/icon-account-multiple.svg");
        repeat: no-repeat;
        size: 10%;
      }
    }

    .preferencesDiv {
      margin-top: 5px;
    }

    .preferencesValue {
      display: block;
      color: #061c2f;
      font-size: 11px;
      line-height: 13px;
      font-weight: 100;
      margin: 0;
      margin-left: -2px;
      margin-right: 10px;
      padding-left: 19px;
      background: {
        image: url("/images/icon-kitchen.svg");
        repeat: no-repeat;
        size: 14px;
      }
      text-decoration: none;
      @extend .truncateText;
      max-width: calc(100% - 50px);
    }

    .noAvailableHours {
      @extend .truncateText;
      display: block;
      font-size: 15px;
      // line-height: 47px;
      font-weight: 400;
      color: #061c2f;
      margin: 0;
      padding-top: 20px;
      font-style: normal;
      white-space: normal;
      .redColor {
        color: #fd6a3b;
        font-weight: 400;
      }
    }

    .hoursValue {
      vertical-align: top;
      display: inline-block;
      color: #061c2f;
      font-size: 11px;
      line-height: 13px;
      font-weight: 100;
      margin: 0;
      padding-left: 17px;
      background: {
        image: url("/images/icon-clock.svg");
        repeat: no-repeat;
      }
      text-decoration: none;
      @extend .truncateText;
      max-width: calc(100% - 290px);
    }

    .aboutRestaurantText {
      margin: 18px 0 0 0;
    }
    .seeRestaurantContainer {
      margin: 18px 0 0 0;
      text-align: right;
    }
    .availableHoursLabel {
      margin: 18px 0 8px 0;
      font-size: 13px;
      line-height: 16px;
      color: #061c2f;
      font-weight: 100;
      display: block;
    }
    .availableHoursContainer {
      margin: 0 0 0 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      box-sizing: border-box;
      position: relative;
      margin-left: -18px;

      .availableHoursContainer__body {
        align-self: flex-start;
        width: calc(100% - 180px);
        margin-right: 20px;
        box-sizing: border-box;
        display: inline-block;

        .horizontalScrollMenuContainer
          .menu-wrapper--inner
          > .menu-item-wrapper
          .menu-item {
          line-height: 33.5px;
          padding: 5px 10px;
        }
      }
      .availableHoursContainer__submit {
        align-self: flex-start;
        width: 200px;
        box-sizing: border-box;
        display: inline-block;

        .MSButton {
          min-width: 100%;
        }
      }
    }

    .locationValue + .hoursValue {
      //margin: 8px 0 0 0;
    }

    .restaurantLine__image,
    .restaurantLine__details {
      display: inline-block;
      vertical-align: top;
      position: relative;
    }

    .restaurantLine__image {
      width: 240px;
      min-height: 240px;
      display: inline-block;

      .restaurantLogo {
        width: 240px;
        height: 240px;
        object-fit: cover;
        object-position: center;
        display: block;
      }

      .noImage {
        width: 240px;
        height: 240px;
        background-size: 46% auto;
      }

      .loaderMask {
        width: 240px;
        height: 240px;
        background-color: #fff;
      }
    }

    .restaurantLine__details {
      width: 100%;
      padding-left: 0;
      position: relative;
      //min-height: 240px;

      .logoAnchor {
        border: 0;
      }

      .restaurantLogo {
        margin: 0;
        max-width: 70px;
        max-height: 52px;
        object-fit: contain;
        object-position: center;
        display: block;
        position: absolute;
        top: 5px;
        right: 5px;
      }
    }

    &.restaurantLine--small {
      .restaurantLine__image {
        width: 180px;
        min-height: 180px;

        .restaurantLogo {
          width: 180px;
          height: 180px;
        }

        .noImage {
          width: 180px;
          height: 180px;
          background-size: 46% auto;
        }

        .loaderMask {
          width: 180px !important;
          height: 180px !important;
          background-color: #fff;
        }
      }
      .restaurantLine__details {
        min-height: 0;
        padding-left: 0;
        width: calc(100%);
        padding-right: 0;
      }
    }

    @media only screen and (max-width: 790px) {
      .restaurantLine__image {
        width: 200px;
        min-height: 200px;

        .restaurantLogo {
          width: 200px;
          height: 200px;
        }

        .noImage {
          width: 200px;
          height: 200px;
          background-size: 46% auto;
        }

        .loaderMask {
          width: 200px !important;
          height: 200px !important;
          background-color: #fff;
        }
      }

      .restaurantLine__details {
        width: 100%;
        padding-left: 0;
        position: relative;
      }
    }

    @media only screen and (max-width: 732px) {
      .hoursValue {
        display: block;
        max-width: calc(100% - 100px);
      }

      .availableHoursContainer {
        display: block;
        margin-left: 0;

        .availableHoursContainer__body {
          display: block;
          width: 100%;
          margin-bottom: 20px;
        }

        .availableHoursContainer__submit {
          display: block;
          width: 100%;
        }
      }

      .restaurantLine__image {
        width: 100%;
        min-height: 140px;
        display: block;

        .noImage {
          width: 100%;
          height: 140px;
          background-size: auto;
        }

        .loaderMask {
          width: 100% !important;
          height: 140px !important;
          background-color: #fff;
        }

        .restaurantLogo {
          width: 100%;
          height: 140px;
        }
      }

      .restaurantLine__details {
        display: block;
        width: 100%;
        padding: 20px 0 0 0;

        .restaurantLogo {
          top: 20px;
          right: 0;
        }
      }
    }

    @media only screen and (max-width: 462px) {
      .searchMapResultItem
        .restaurantLine
        .restaurantLine__details
        .restaurantLogo {
        display: none;
      }
      .searchMapResultItem .restaurantLine .restaurantName {
        padding-right: 0;
      }
    }
  }
}
