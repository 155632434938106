@import "../../styles/helpers/text.scss";
@import "../../styles/ownSlider.scss";

.indexPageContainer {
  .mostPopularWrapper {
    .restaurantBox,
    .pagination {
      transition: opacity 0.15s ease-in-out;
      opacity: 1;
    }

    &.mostPopularWrapper--isLoading {
      .restaurantBox,
      .pagination {
        opacity: 0.66;
      }
    }
  }

  .whiteBox {
    background-color: #ffffff;
    box-shadow: 0 0 15px rgba(2, 53, 98, 0.04);
    padding: 20px 20px 20px 20px !important;
    border-radius: 4px;
    display: block;
  }

  .googleMapContainer {
    height: calc(100vh - 184px) !important;
    min-height: 430px !important;
    width: calc(100vh) !important;
    min-width: 130px !important;
    max-width: 100% !important;
    position: relative !important;

    .gm-style .gm-style-iw-t {
      display: none !important;
    }

    .infoBox {
      & > img {
        cursor: pointer;
        margin: 0;
        width: 16px !important;
        position: absolute !important;
        right: 10px !important;
        z-index: 10 !important;
        top: 10px !important;
      }
    }

    .refreshMapButton {
      @extend .unselectable;
      padding: 10px;
      background-color: #fff;
      color: #666666;
      position: absolute;
      bottom: 24px;
      right: 60px;
      border-radius: 2px;
      box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2);
      font-size: 12px;
      line-height: 22px;
      height: 21px;
      cursor: pointer;

      &:hover {
        color: #061c2f;
      }

      &.refreshMapButton--hidden {
        display: none;
      }
    }
  }

  .standardPageContainer__inside {
    display: block;
  }

  .noImage {
    width: 100%;
    height: 120px;
  }

  .loaderMask {
    width: 100%;
    height: 120px;
    background-color: #fafdff;
  }

  .categoryName {
    text-decoration: none;
    display: block;
    color: #556dff;
    font-size: 16px;
    line-height: 14px;
    font-weight: 500;
    margin: 0 0 12px 0;
  }

  .slider__row {
    display: inline;
  }

  .slider__row_left {
    display: inline-block;
    text-align: left;
    width: 50%;
  }

  .slider__row_right {
    display: inline-block;
    text-align: right;
    width: 50%;
  }

  .restaurantName {
    @extend .truncateText;
    text-decoration: none;
    display: block;
    color: #061c2f;
    font-size: 17px;
    line-height: 20px;
    font-weight: 500;
    margin: 0 0 6px 0;
    max-width: 100%;
  }

  .locationValue {
    text-decoration: none !important;
    display: block;
    color: #061c2f;
    font-size: 11px;
    line-height: 13px;
    font-weight: 100;
    margin: 0;
    padding-left: 17px;
    background: {
      image: url("/images/icon-location.svg");
      repeat: no-repeat;
    }
  }

  .preferencesDiv {
    min-height: 13px;
  }

  .preferencesValue {
    color: #061c2f;
    font-size: 11px;
    line-height: 13px;
    font-weight: 100;
    margin: 0;
    padding-left: 17px;
    background: {
      image: url("/images/icon-kitchen.svg");
      repeat: no-repeat;
      size: 14px;
    }
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    min-height: 13px;
  }

  .locationValuePromo {
    text-decoration: none !important;
    display: inline;
    color: #061c2f;
    font-size: 11px;
    line-height: 13px;
    font-weight: 100;
    margin: 0;
    padding-left: 17px;
    text-align: right;
    background: {
      image: url("/images/icon-location.svg");
      repeat: no-repeat;
    }
  }

  .promotionHoursValue {
    text-decoration: none !important;
    display: -webkit-box;
    -webkit-align-items: center;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    color: #061c2f;
    font-size: 13px;
    line-height: 16px;
    font-weight: 100;
    margin: 0;
    margin-top: 12px;
    padding-left: 20px;
    height: 30px;
    text-overflow: ellipsis;
    overflow: hidden;
    background: {
      image: url("/images/calendar-clock.svg");
      repeat: no-repeat;
      size: 15px;
    }
    background-position: left top;
    &.promotionRestaurantDescription {
      background: none;
      padding-left: 2px;
      margin: 0;
      height: 31px;
    }
  }

  .hoursValue {
    color: #061c2f;
    font-size: 11px;
    line-height: 13px;
    font-weight: 100;
    padding-bottom: 2px;
    margin: 0;
    padding-left: 17px;
    background: {
      image: url("/images/icon-clock.svg");
      repeat: no-repeat;
    }
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    min-height: 26px;
  }

  .locationValue + .hoursValue {
    margin: 8px 0 0 0;
  }

  .promotionText {
    font-size: 17px;
    line-height: 20px;
    color: #061c2f;
    margin: 0;
    font-weight: 500;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    // min-height: 51px;
  }

  .aboutRestaurantText {
    font-size: 13px;
    line-height: 16px;
    font-weight: 100;
    color: #061c2f;
    margin: 18px 0 0 0;

    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    min-height: 48px;
  }
  .title {
    text-decoration: none;
    display: block;
    color: #061c2f;
    font-size: 17px;
    line-height: 20px;
    font-weight: 500;
    min-height: 60px;
    margin: 0 0 6px 0;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .author {
    font-size: 13px;
    line-height: 16px;
    font-weight: 100;
    color: #061c2f;
  }

  .moreInfo,
  .bookingAnchor {
    display: inline-block;
    text-decoration: none;
    font-size: 14px;
    line-height: 17px;
    color: rgb(253, 106, 59);
    font-weight: 400;
    margin: 18px 0 0 0;
    padding-right: 15px;
  }

  .moreInfo {
    display: inline-block;
    cursor: pointer;
    margin: 0;
    text-decoration: none;
    margin-bottom: 18px;
  }

  .iconInfo {
    position: absolute;
    right: 20px;
    cursor: pointer;
    display: inline-block;
    height: 17px;
    width: 17px;
    background: {
      image: url("/images/icon_information_orange.svg");
      repeat: no-repeat;
      position: right top;
    }
  }

  .restaurantImage {
    margin: 0;
    width: 100%;
    height: 120px;
    object-fit: cover;
    object-position: center;
    display: block;
    background-color: #fafdff;
  }

  .promotionImage {
    margin: 0;
    width: 100%;
    height: 200px;
    object-fit: cover;
    object-position: center;
    display: block;
    background-color: #fafdff;
  }

  .articleImage {
    margin: 0;
    width: 100%;
    height: 240px;
    object-fit: cover;
    object-position: center;
    display: block;
    background-color: #fafdff;
  }

  h2 {
    margin: 0;
    text-align: left;
    font-size: 22px;
    color: #061c2f;
    line-height: 28px;
    font-weight: lighter;

    strong {
      font-weight: 500;
    }
  }

  h2 {
    margin: 0 0 25px 0;
    position: relative;

    .viewSwitcher {
      top: 0;
      right: 0;
      position: absolute;
      display: inline-block;
      text-align: right;

      .viewSwitcher__item {
        display: inline-block;
        font-size: 15px;
        line-height: 18px;
        font-weight: 100;
        text-decoration: none;
        color: #061c2f;
        padding-left: 20px;
        background: {
          repeat: no-repeat;
          position: left center;
        }
        margin-left: 25px;

        &.viewSwitcher__item--list {
          background-image: url("/images/icon-list.svg");

          &.viewSwitcher__item--active {
            background-image: url("/images/icon-list-active.svg");
          }
        }

        &.viewSwitcher__item--map {
          background-image: url("/images/icon-map.svg");

          &.viewSwitcher__item--active {
            background-image: url("/images/icon-map-active.svg");
          }
        }

        &.viewSwitcher__item--active {
          color: #556dff;
        }
      }
    }
  }

  .mostPopularTitle {
    display: inline-block !important;
    margin-right: 70px;
  }

  h2 {
    margin: 0 0 18px 0;
  }
  .restaurantBox {
    display: block;
    margin: {
      left: -8px;
      right: -8px;
      bottom: 22px;
    }

    .restaurantBox__item {
      @extend .unselectable;
      display: inline-block;
      width: 25%;
      vertical-align: top;
      text-decoration: none;

      .restaurantBox__item-inside {
        margin: 0 8px 24px 8px;
        outline: none;
        transition: all 0.2s ease-in;
        background-color: #ffffff;
        box-shadow: 0 2px 8px rgba(2, 53, 98, 0.03);
        //min-height: 382px;
        border-radius: 3px;
        overflow: hidden;
        position: relative;

        &:hover,
        &:focus {
          box-shadow: 0 2px 13px rgba(2, 53, 98, 0.102);
        }

        .restaurantImage {
          width: 100%;
          height: 120px;
          object-fit: contain;
          object-position: center;
          display: block;
          background-color: #e4eff3;
          object-fit: cover;
          box-sizing: border-box;
        }

        .loaderMask {
          background-color: #e4eff3;
        }

        .ratingStars {
          display: block;
          margin: 0;
          top: 9px;
          right: 9px;
          position: absolute;
          z-index: 2;
          text-align: right;
        }

        .restaurantBox__item-insideBox {
          padding: 20px;
        }

        .restaurantBox__item-insideSeparator {
          display: block;
          width: 100%;
          height: 1.5px;
          background: {
            image: url("/images/restaurant-box-vertical-separator-background.svg");
            repeat: repeat-x;
            position: center top;
          }
        }
      }
    }
  }

  .slider__item {
    min-height: 400px;

    .slider-details-container {
      min-height: 104px;
    }

    .slider__itemContainer {
      padding: 10px 20px;
    }

    .slider__itemContainerNarrow {
      padding: 1px 20px;
    }
  }
  .slick-slider {
    .slick-dots {
      bottom: -31px;
    }

    .slick-list {
      padding-top: 0 !important;
    }
  }

  @media only screen and (max-width: 1190px) {
    .slider {
      .slick-prev {
        left: -40px;
      }
      .slick-next {
        right: -40px;
      }
    }
  }

  @media only screen and (max-width: 990px) {
    .indexPageContainer__inside {
      padding: 20px 20px 20px 20px !important;
      box-sizing: border-box;
    }
    .slider .slider__item,
    .sliderAlternative .slider__item {
      min-height: 0;
    }

    .slider__row {
      display: block;
    }

    .slider__row_left {
      display: block;
      width: 100%;
    }

    .slider__row_right {
      display: block;
      width: 100%;
    }

    .restaurantName {
      display: block;
    }

    .locationValue {
      display: block;
      text-align: left;
    }

    .locationValuePromo {
      display: block;
      text-align: left;
    }

    .restaurantBox .restaurantBox__item {
      width: 50%;

      .restaurantBox__item-inside {
        margin: 0 8px 16px 8px;
      }
    }
    .sliderAlternative {
      margin-left: -8px;
      margin-right: -8px;
      .slider__item {
        display: inline-block;
        width: calc(50% - 16px);
        margin: 6px 8px;
      }

      // &.sliderAlternative--correction {
      //   display: none !important;
      // }
    }

    h2 {
      margin-bottom: 20px !important;
      padding-left: 20px !important;
      padding-right: 20px !important;

      .viewSwitcher {
        right: 20px;
      }
    }
  }
  @media only screen and (max-width: 400px) {
    h2 {
      font-size: 17px;
    }

    .slick-dots {
      display: none !important;
    }

    .slider__row {
      display: block;
    }

    .slider__row_left {
      display: block;
      width: 100%;
    }

    .slider__row_right {
      display: block;
      width: 100%;
    }

    .restaurantName {
      display: block;
    }

    .locationValue {
      display: block;
      text-align: left;
    }

    .locationValuePromo {
      display: block;
      text-align: left;
    }

    .sliderContainer {
      margin-bottom: 38px;
    }
    .restaurantBox .restaurantBox__item {
      width: 100%;
    }

    .slider {
      position: relative;

      &.slider--mobile {
        display: block;
      }
      &.slider--desktop {
        display: none;
      }

      &.sliderAlternative--mobile {
        display: block;
        opacity: 1;
        max-height: auto;
        overflow: auto;
      }

      .slick-list {
        padding-bottom: 0 !important;
      }

      // &::after {
      //   content: '';
      //   height: 100%;
      //   width: 28%;
      //   position: absolute;
      //   right: -1px;
      //   top: 0;
      //   background: linear-gradient(90deg, rgba(0, 0, 0, 0) 5%, rgba(255, 255, 255, 0.85) 95%);
      //   pointer-events: none;
      // }

      display: block;
      .slick-next,
      .slick-prev {
        display: none !important;
        z-index: 3;
      }

      .slick-next {
        right: -12px;
      }
      .slick-prev {
        left: -12px;
      }
    }

    .sliderAlternative {
      display: none;

      &.sliderAlternative--mobile {
        display: block;

        & > .slider__item {
          width: calc(100% - 16px);
          margin: 0 8px 16px 8px;

          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 732px) {
  h2 {
    .viewSwitcher {
      .viewSwitcher__item {
        &.viewSwitcher__item--map {
          &.viewSwitcher__item--hide {
            display: none;
          }
        }
        &.viewSwitcher__item--list {
          &.viewSwitcher__item--hide {
            display: none;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 576px) {
  .indexPageContainer .promotionImage {
    height: auto;
  }
}
