.checkboxContainer {
  display: block;
  font-size: 13px;
  margin: 9px 0;
  color: #262a4c;
  line-height: 20px;
  background-color: transparent;
  border: 0;
  padding: 0;
  text-align: left;

  &:active,
  &:focus {
    outline: 0;
  }

  input[type="checkbox"] {
    display: none;
  }

  .aka-label {
    display: block;
    transition: all 0.2s ease-in-out;
    cursor: pointer;
    position: relative;

    &::before {
      position: absolute;
      transition: all 0.2s ease-in-out;
      content: "";
      display: inline-block;
      width: 22px;
      height: 22px;
      color: transparent;
      border: 1px solid #e0e0e0;
      line-height: 13px;
      padding: 3px;
      text-align: center;
      margin-right: 16px;
      border-radius: 3px;
      font-size: 10px;
      background: {
        color: #ffffff;
        position: center;
        repeat: no-repeat;
      }
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.05);
    }

    .labelValue {
      padding-left: 45px;
      display: inline-block;
      margin-top: -2px;
      font-size: 13px !important;
      line-height: 17px !important;
      font-weight: 100 !important;
    }

    &.singleLine {
      .labelValue {
        margin-top: 7px;
      }
    }
  }

  input:checked + .aka-label {
    &::before {
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0);
      color: #262a4c;
      background-image: url("/images/checkbox-tick.svg");
    }
  }
}
