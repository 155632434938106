//Colors
$color-white: #ffffff;
$color-black: #000;
$color-grey-light: #ebebeb;
$color-grey-dark: #707070;

$color-primary: #023562;
$color-primary-light: #556dff;
$color-primary-dark: #061c2f;

$color-orange: #fd6a3b;

//Radio Switch
$radio-switch-width: 100px;
$radio-switch-height: 20px;
$radio-switch-padding: 3px;
$radio-switch-radius: 1000px;
$radio-switch-animation-duration: 0.3s;
