@import url(https://fonts.googleapis.com/css?family=Rubik:300,400,500,700&display=swap);
@import "./slick.scss";
@import "./form.scss";
@import "./button.scss";
@import "./imageLoader.scss";
@import "./modal.scss";
@import "./ratingStars.scss";
@import "./loader.scss";

body {
  font-family: "Rubik", sans-serif;
  margin: 0;
  padding: 0;
  background-color: #fafdff;
  &.plugin-mode {
    background-color: transparent;
    #root {
      background-color: #fff;
      .mainContainer--plugin {
        background-color: #fff;
      }
      .restaurantProfilePageContainer .whiteBox--reservationSummary,
      .restaurantProfilePageContainer .whiteBox {
        background-color: #fff;
      }
    }
  }

  input,
  textarea,
  select {
    font-family: "Rubik", sans-serif !important;
  }

  * {
    -webkit-tap-highlight-color: transparent;
  }

  *,
  *:focus,
  *:active {
    outline: none !important;
  }

  button::-moz-focus-inner {
    border: 0;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus input:-webkit-autofill,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0px 1000px white inset !important;
  }
}

::-moz-selection {
  background-color: #556dff;
  color: #ffffff;
}
::selection {
  background-color: #556dff;
  color: #ffffff;
}

a {
  color: #556dff;

  &:hover {
    color: #556dff;
    text-decoration: none;
  }
}

.mainContainer {
  margin-top: 70px;
  min-height: calc(100vh - 358px);

  &--plugin {
    margin-top: 0;
  }

  .standardPageContainer__inside {
    display: block;
    margin: 0 auto;
    width: 1088px;
    max-width: calc(100% - 110px);
    padding: 44px 55px 44px 55px;

    .whiteBox {
      .alertInformation {
        margin: -30px -30px 0 -30px;
        display: block;
        text-align: center;
        padding: 15px 30px;
        border-radius: 3px;
        background-color: #556dff;
        color: #ffffff;
        font-size: 13px;
        line-height: 18px;
        font-weight: 100;

        h4,
        p {
          text-align: left;
          color: #ffffff;
        }

        h4 {
          margin: 0;
          font-size: 16px;
          line-height: 28px;
          font-weight: 400;
        }

        p {
          margin: 0 0 5px 0;
          font-size: 14px;
          line-height: 20px;
          font-weight: 100;
        }
      }
    }
  }

  .narrowerPageContainer__inside {
    margin: 0 auto;
    width: 904px;
    max-width: calc(100% - 110px);
    padding: 34px 55px 44px 55px;
    display: block;

    .alertInformation {
      margin: -30px -30px 0 -30px;
      display: block;
      text-align: center;
      padding: 15px 30px;
      border-radius: 3px;
      background-color: #556dff;
      color: #ffffff;
      font-size: 13px;
      line-height: 18px;
      font-weight: 100;

      h4,
      p {
        text-align: left;
        color: #ffffff;
      }

      h4 {
        margin: 0;
        font-size: 16px;
        line-height: 28px;
        font-weight: 400;
      }

      p {
        margin: 0 0 5px 0;
        font-size: 14px;
        line-height: 20px;
        font-weight: 100;
      }
    }
  }
}

button {
  font-family: "Rubik", sans-serif;
}

.hideOnTablet {
  //display: none;
}

.hideOnPhone {
  display: none;
}

.hideOnPC {
  display: none;
}

.logo {
  width: 142px;
}
@media only screen and (max-width: 990px) {
  .hideOnTablet {
    display: none;
  }
  .hideOnPC {
    display: block;
  }
  .mainContainer {
    .standardPageContainer__inside {
      width: 100%;
      max-width: 100%;
      padding-left: 0;
      padding-right: 0;

      .whiteBox {
        //margin-left: -20px;
        border-radius: 0;
        //margin-right: -20px;
        padding: 0 20px 0 20px;
      }
    }

    .narrowerPageContainer__inside {
      width: 100%;
      max-width: 100%;
      padding-left: 0;
      padding-right: 0;

      h2,
      .subheader {
        padding: 0 20px;
      }

      .whiteBox {
        //margin-left: -20px;
        border-radius: 0;
        //margin-right: -20px;
        padding: 30px 20px 30px 20px;

        .alertInformation {
          margin: -30px -20px 0 -20px;
          border-radius: 0;
        }
      }
    }
  }
}

// @media only screen and (max-width: 990px)  {
//     .mainContainer {
//         .standardPageContainer__inside {
//             padding-top: 20px;
//         }
//     }
// }

.mojstolik-phone-input {
  padding: 0 !important;
}

.mojstolik-phone-input input.form-control {
  // background: red;
  border: 0;
  height: 100%;
  width: 100%;
}

.react-tel-input.mojstolik-phone-input .flag-dropdown {
  border: 0;
  border-right: 1px solid #e0e0e0;
}
.react-tel-input.mojstolik-phone-input .country-list {
  text-align: left;
}
