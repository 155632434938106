@import "./helpers/text.scss";

.MSButton {
  @extend .unselectable;
  box-sizing: border-box;
  text-align: center;
  position: relative;
  transition: all 0.2s ease-in;
  line-height: 16px;
  font-size: 15px;
  padding: 13.75px 40px 13.75px 40px;
  font-weight: 400;
  min-width: 200px;
  color: #ffffff !important;
  background-color: #fd6a3b;
  border: 1px solid #fd6a3b;
  border-radius: 3px;
  overflow: hidden;
  outline: 0;
  display: inline-block;
  height: auto;
  text-decoration: none;

  &:hover {
    background-color: #e35a2f;
  }

  &.MSButton--disabled,
  &[disabled] {
    filter: grayscale(1);
    pointer-events: none;
    opacity: 0.5;
  }

  &.MSButton--pointer {
    cursor: pointer;
  }

  &.MSButton--orange {
    color: #ffffff !important;
    background-color: #fd6a3b;
    border: 1px solid #fd6a3b;

    &:hover {
      background-color: #e35a2f;
    }
  }

  &.MSButton--discount {
    height: 100%;
  }

  &.MSButton--white-orange {
    color: #fd6a3b !important;
    background-color: #ffffff;
    border: 1px solid #fd6a3b;

    &:hover {
      background-color: #fd6a3b;
      color: #ffffff !important;
    }
  }

  &.MSButton--darkBlue {
    color: #ffffff !important;
    background-color: #061c2f;
    border: 1px solid #ffffff;

    &:hover {
      background-color: rgb(3, 17, 29);
    }
  }

  &.MSButton--white {
    background-color: #ffffff;
    border-color: #071b2f;
    color: #071b2f !important;

    &:hover {
      background-color: #ecf3f8;
    }
  }

  &.MSButton--withoutBorder {
    border-color: transparent;
    background-color: #ffffff;
    color: #071b2f !important;

    &:hover {
      background-color: #ffffff;
    }
  }

  &.MSButton--disabled {
    pointer-events: none;
    filter: grayscale(100%);
    opacity: 0.5;
  }
}
