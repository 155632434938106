@import "../styles/helpers/variables.scss";

.scroll-button {
  opacity: 0;
  transition: opacity 0.4s;
  display: none;
  cursor: pointer;

  &__visible {
    position: fixed;
    cursor: pointer;
    opacity: 1;
    right: 32px;
    bottom: 32px;
    width: 64px;
    height: 64px;
    background-color: $color-orange;
    border: 1px solid $color-orange;
    border-radius: 10000px;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
    transition: opacity 0.4s;

    .button-arrow {
      position: relative;
      width: 100%;
      height: 100%;

      &::after {
        position: absolute;
        content: "";
        width: 100%;
        height: 100%;
        left: 0;
        background: url(../images/up-arrow-svgrepo-com.svg);
        color: $color-white;
        background-repeat: no-repeat;
        background-position-x: center;
        background-position-y: center;
      }
    }
  }
  @media only screen and (max-width: 560px) {
    &__visible {
      display: none;
    }
  }
}
