@import "../../styles/helpers/text.scss";

.dropDownListSuggestContainer {
  position: relative;

  .react-select__menu {
    z-index: 4 !important;
    margin-top: 1px;
  }

  .react-select__control {
    transition: all 0.2s ease-in-out;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    min-height: 55.5px !important;
    &:hover {
      border-color: #e0e0e0;
    }

    &.react-select__control--is-focused {
      box-shadow: none;
      border: 1px solid #061c2f;
    }

    .react-select__value-container {
      position: relative;
      top: 0;
      padding: 0 12px 0 12px; //8px 12px 29px 12px;
      height: 52px;

      .react-select__placeholder {
        position: absolute;
        color: #cbcace;
        margin-left: 2px;
        margin-top: 7.5px;
      }
    }

    .react-select__input input[type="text"] {
      margin-top: 15px;
    }

    .react-select__single-value {
      font-size: 15px;
      line-height: 18px;
      font-weight: 400;
      margin-top: 7.5px;
      margin-left: 2px;
    }

    .react-select__indicator-separator {
      display: none;
    }

    .react-select__dropdown-indicator {
      margin-right: 11px;
      background: {
        image: url("/images/icon-select-arrow-bottom.svg");
        position: center 10px;
        repeat: no-repeat;
      }
      width: 18px;
      height: 36px;

      svg {
        display: none;
      }
    }

    .react-select__indicator {
      transition: all 0.2s ease-in;
      position: relative;
      color: #fd6a3b;
      padding: 8px 3px;

      &.react-select__clear-indicator {
        transition: all 0.2s ease-in-out;
        color: #fd6a3b;
        padding: 8px 0px;

        &:hover,
        &:active {
          opacity: 1;
        }

        svg {
          transition: all 0.2s ease-in;
          position: relative;
          width: 15px;
          height: 15px;
        }
      }
    }

    &.react-select__control--menu-is-open {
      .react-select__dropdown-indicator {
        transform: rotate(-180deg);
      }
    }
  }

  .react-select__option {
    transition: all 0.2s ease-in-out;

    &.react-select__option--is-focused {
      background-color: #eef5f9;
    }

    &.react-select__option--is-selected {
      background-color: #023562 !important;
      color: #ffffff;
      font-weight: 400;
    }
  }

  .buttonIcon {
    display: inline-block;
    padding: 4px;
    border-radius: 90%;
    color: #000000;
    background-color: #ffffff;
    position: absolute;
    top: 34px;
    right: 0;
    width: 32px;
    height: 32px;
    cursor: pointer;
    transform: scale(0.6);
    z-index: 1;

    svg {
      position: relative;
    }
  }

  .required-sign {
    color: red;
    padding-left: 4px;
  }
}

.dropDownListSuggest .dropDownListSuggestLabel {
  margin: 0 0 5px 0;
  font-size: 12px;
  color: #000;
  display: block;
  width: 100%;
}
.dropDownListSuggestLabel {
  pointer-events: none;
  padding: 0 15px;
  font-size: 11px;
  margin: 13px 0 7px 0;
  color: #767676;
  line-height: 13px;
  position: absolute;
  z-index: 1;
  font-weight: 100;
  max-width: calc(100% - 31px);
  @extend .truncateText;
}
.dropDownListSuggest {
  position: relative;
  transition: all 0.2s ease-in-out;
  padding: 0;
  font: {
    size: 14px;
    weight: 300;
  }
  outline: none;
  width: 100%;
  display: inline-block;
  color: #495057;
  margin: 0;

  .react-select__menu-list {
    overflow: auto;
    max-height: 189px;
  }

  &::placeholder {
    color: #f1f1f1;
    font-weight: 300;
    opacity: 1;
  }

  &:-ms-input-placeholder {
    color: #f1f1f1;
    font-weight: 300;
  }

  &::-ms-input-placeholder {
    color: #f1f1f1;
    font-weight: 300;
  }
}

@media only screen and (max-width: 990px) {
  .dropDownListSuggestContainer
    .react-select__option.react-select__option--is-focused {
    background-color: transparent;
  }
}

@media only screen and (max-width: 380px) {
  .dropDownListSuggestContainer
    .react-select__control
    .react-select__single-value {
    font-size: 14px;
  }
}
