.sliderContainer {
  transition: all 0.75s ease-in;
  opacity: 0;
  max-height: 0;
  overflow: hidden;

  &.sliderContainer--show {
    max-height: 100000px;
    opacity: 1;
    overflow: visible;
  }
  .desktop-one-slide {
    width: 50%;
  }
}

.slider,
.sliderAlternative {
  margin: {
    top: -5px;
    left: -8px;
    right: -8px;
    bottom: 17px;
  }

  &.slider--mobile {
    display: block;
    width: 80%;
    margin: 0 auto;
  }

  .slick-dots {
    display: block !important;
  }

  .ft-slick__dots--custom {
    height: 4px;
    width: 56px;
    background-color: #e5e7e9;
    border-radius: 0;
    // position: relative;
  }

  .slick-dots li {
    width: 56px;
    // margin: 0 2px;
    // transition: width 0.3s ease-in-out;
  }

  .slick-dots .slick-active {
    width: 56px;
    // transition: width 0.3s ease-in-out;
  }

  .slick-dots .slick-active .ft-slick__dots--custom {
    width: 56px;
    // top: 0px;
    // overflow: hidden;
    background-color: rgb(253, 106, 59);

    // .loading {
    // height: 8px;
    // animation: loading 5s ease-in;
    // background-image: linear-gradient(270deg, #4fd15a, #03ac0e);
    // display: inline-block;
    // position: absolute;
    // top: 0;
    // left: 0;
    // border-radius: 0;
    // }
  }

  .slick-next,
  .slick-prev {
    transition: all 0.2s ease-in;
    top: 50%;
    width: 40px;
    height: 40px;
    background: {
      repeat: no-repeat;
    }
    position: absolute;

    &::before {
      display: none !important;
    }

    &:hover {
      //
    }
  }

  .slick-prev {
    background-image: url("/images/icon-slider-left-arrow.svg");
    left: -46px;
  }

  .slick-next {
    background-image: url("/images/icon-slider-right-arrow.svg");
    right: -46px;
  }

  .slick-list {
    padding: 15px 0 30px 0;
  }

  .slick-slide {
    & > div:not([class]) {
      margin: 0px 8px 8px 8px;
      position: relative;
    }
  }

  .slider__item {
    outline: none;
    transition: all 0.2s ease-in;
    background-color: #ffffff;
    box-shadow: 0 2px 8px rgba(2, 53, 98, 0.03);
    min-height: 440px;
    border-radius: 3px;
    overflow: hidden;
    vertical-align: top;
    text-decoration: none;

    &:hover,
    &:focus {
      box-shadow: 0 2px 13px rgba(2, 53, 98, 0.102);
    }

    .slider__itemAnchor {
      position: relative;
    }

    .slider__itemContainer {
      display: block;
      margin: 0;
      padding: 20px;
      position: relative;
    }

    .slider__itemSeparator {
      display: block;
      width: 100%;
      height: 1.5px;
      background: {
        image: url("/images/restaurant-box-vertical-separator-background.svg");
        repeat: repeat-x;
        position: center top;
      }
    }
  }

  &.sliderAlternative--correction {
    margin-top: 5px;

    .slider__item {
      display: inline-block;
      width: 50%;
      vertical-align: top;
      background-color: transparent;
      box-shadow: none;
      min-height: none;
      border-radius: 0;

      .slider__item-inside {
        margin: 0 8px 12px 8px;
        background-color: #ffffff;
        box-shadow: 0 2px 8px rgba(2, 53, 98, 0.03);
        //min-height: 375px;
        border-radius: 3px;
        overflow: hidden;
      }
    }
  }
}

.sliderAlternative--mobile {
  display: none;
}
