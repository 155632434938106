@import "../../styles/datepicker.scss";
@import "../../styles/helpers/text.scss";

.datepickerContainer {
  position: relative;
  cursor: default;

  .required-sign {
    color: red;
    padding-left: 4px;
  }

  .react-datepicker {
    .react-datepicker-container {
      width: 225px;

      .react-datepicker-top .week-days {
        background-color: #546cfd;
      }

      .react-datepicker-body
        .react-datepicker-row
        .react-datepicker-picker.selected.current {
        color: #ffffff;
        background: #fd6a3b;
        border-color: #fd6a3b;
        font-weight: 400;
      }

      .react-datepicker-top {
        text-align: center;
        background: #023562;
        color: #fff;
        border-top: 1px solid #023562;
        border-left: 1px solid #023562;
        border-right: 1px solid #023562;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
      }

      .react-datepicker-top .display .react-datepicker-button strong {
        font-weight: 400;
      }

      .react-datepicker-body
        .react-datepicker-row
        .react-datepicker-picker.current {
        font-weight: 400;
      }
    }

    &.floating {
      margin-left: 0;
      z-index: 9;
    }
  }

  .react-datepicker:not(.position-top)
    .react-datepicker-container
    .react-datepicker-top:after {
    border-bottom-color: #023562;
    border-width: 5px;
    margin-left: -5px;
  }

  .react-datepicker-component {
    .react-datepicker-input {
      position: relative;
      min-width: 150px;
      height: 38.5px;
      background: #ffffff;
      transition: all 0.2s ease-in-out;
      border: 1px solid #e0e0e0;
      border-radius: 4px;
      padding-top: 15px;
      @extend .unselectable;

      input {
        // @extend .unselectable;
        cursor: default;
        font-size: 15px;
        font-weight: 400;
        padding-left: 15px;
        padding-right: 15px;
      }

      .button-wrapper {
        display: none !important;
      }

      &.is-open {
        border-color: #061c2f;
      }
    }
  }
}

.datepicker .datepickerLabel {
  margin: 0 0 5px 0;
  font-size: 12px;
  color: #000;
  display: block;
  width: 100%;
}
.datepickerLabel {
  pointer-events: none;
  padding: 0 15px;
  font-size: 11px;
  margin: 13px 0 7px 0;
  color: #767676;
  line-height: 13px;
  position: absolute;
  top: 0;
  z-index: 1;
  font-weight: 100;
}
.datepicker {
  position: relative;
  transition: all 0.2s ease-in-out;
  padding: 0;
  font: {
    size: 14px;
    weight: 300;
  }
  outline: none;
  width: 100%;
  display: inline-block;
  color: #495057;
  margin: 0;

  &:focus {
    border-color: blue;
  }

  &::placeholder {
    color: #f1f1f1;
    font-weight: 300;
    opacity: 1;
  }

  &:-ms-input-placeholder {
    color: #f1f1f1;
    font-weight: 300;
  }

  &::-ms-input-placeholder {
    color: #f1f1f1;
    font-weight: 300;
  }
}

@media only screen and (max-width: 380px) {
  .datepickerContainer {
    .react-datepicker-component {
      .react-datepicker-input {
        input {
          font-size: 14px;
        }
      }
    }
  }
}
