@import "../../styles/helpers/text.scss";

.mobileMenu,
.mobileUserMenu {
  transition: all 0.3s ease-in;
  //display: block;
  z-index: 999;
  left: 0;
  top: 70px;
  position: fixed;
  background-color: transparent;
  width: 100%;
  height: auto;
  overflow-y: auto;
  height: calc(100vh - 70px);
  //max-height: calc(100% - 70px);
  opacity: 0;
  transform: translateY(-100%);
  display: none;

  & > .mobileMenu__item,
  & > .mobileUserMenu__item {
    position: relative;
    cursor: pointer;
    display: block;
    border-top: 1px solid #173b5a;
    @extend .unselectable;

    .mobileMenu__itemAnchor,
    .mobileUserMenu__itemAnchor {
      display: inline-block;
      width: calc(100% - 40px);
      height: 20px;
      padding: 20px;
      font-size: 15px;
      line-height: 20px;
      color: #ffffff;
      background-color: #061c2f;
      text-decoration: none;
      font-weight: 100;

      &:hover {
        background-color: #0a253c;
      }

      &.active {
        font-weight: 500;
        color: #556dff;
      }

      &.notactive {
      }

      canvas {
        color: rgba(87, 109, 255, 0.3);
      }
    }

    &:first-child {
    }
  }

  .mobileMenu__item--downloadApplication {
    text-align: center;
    background-color: #061c2f;
    border-top: 1px solid #173b5a;

    .googlePlay,
    .appStore {
      display: inline-block;
      margin: 13px 15px 10px 15px;
      width: 125px;
      height: 38px;
      background: {
        image: url("/images/google_play-app_store.svg");
        repeat: no-repeat;
        position: -7px -5px;
      }
      cursor: pointer;
      transition: all 0.2s ease-in;
      text-decoration: none;

      &:hover {
        transform: scale(1.05);
      }
    }
    .appStore {
      background-position: -7px -48px;
    }
  }

  .mobileMenu__item--languages {
    border-top: 1px solid #173b5a;
    width: 100%;
    display: flex;
    flex-direction: column;

    & > .languageItem {
      position: relative;
      display: inline-block;
      //width: 50%;
      border-bottom: 1px solid #173b5a;

      height: 20px;
      padding: 20px;
      font-size: 15px;
      line-height: 20px;
      color: #ffffff;
      background-color: #061c2f;
      text-decoration: none;
      font-weight: 100;
      text-align: center;

      canvas {
        color: rgba(87, 109, 255, 0.3);
      }

      .languageIcon {
        position: relative;
        z-index: 2;
        cursor: pointer;
        transition: all 0.2s ease-in;
        width: auto;
        height: 24px;
        display: inline-block;
        background: {
          repeat: no-repeat;
        }
        padding-left: 35px;
        font-size: 15px;
        line-height: 24px;
        @extend .unselectable;

        &.languageIcon--pl {
          background-image: url("/images/icon-language-pl.svg");
        }
        &.languageIcon--en {
          background-image: url("/images/icon-language-en.svg");
        }
        &.languageIcon--de {
          background-image: url("/images/icon-language-de.svg");
        }
        &.languageIcon--es {
          background-image: url("/images/icon-language-es.svg");
        }
        &.languageIcon--uk {
          background-image: url("/images/icon-language-uk.svg");
        }
        &.languageIcon--fr {
          background-image: url("/images/icon-language-fr.svg");
        }

        &:hover {
          transform: scale(1.1);
        }
      }

      &.active {
        .languageIcon {
          border-color: #556dff;
        }
      }

      /* &:first-child {
        width: calc(50% - 1px);
        border-right: 1px solid #173b5a;
      } */
    }
  }

  &.mobileMenu--show,
  &.mobileUserMenu--show {
    opacity: 1;
    transform: translateY(0);
  }
}

.mobileUserMenu {
  z-index: 1000;
}

.plugin-mode {
  .userMenuBackground {
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 3;
    overflow: hidden;
    display: none;

    &.userMenuBackground--opened {
      display: block;
    }
  }
  .languageIcon {
    transition: all 0.2s ease-in;
    width: 24px;
    height: 24px;
    display: inline-block;
    background: {
      repeat: no-repeat;
    }
    overflow: hidden;
    border: 1px solid transparent;
    border-radius: 90%;

    &.languageIcon--pl {
      background-image: url("/images/icon-language-pl.svg");
    }
    &.languageIcon--en {
      background-image: url("/images/icon-language-en.svg");
    }
    &.languageIcon--de {
      background-image: url("/images/icon-language-de.svg");
    }
    &.languageIcon--es {
      background-image: url("/images/icon-language-es.svg");
    }
    &.languageIcon--uk {
      background-image: url("/images/icon-language-uk.svg");
    }
    &.languageIcon--fr {
      background-image: url("/images/icon-language-fr.svg");
    }
  }

  &.active {
    .languageIcon {
      border-color: #556dff;
    }
  }

  &:hover {
    .languageIcon {
      transform: scale(1.1);
    }
  }
  &.headerItem--languages {
    padding-top: 21px;
    display: none;
    & > .anchor {
      display: inline-block;
      cursor: pointer;
      margin-right: 2px;

      .languageIcon {
        transition: all 0.2s ease-in;
        width: 24px;
        height: 24px;
        display: inline-block;
        background: {
          repeat: no-repeat;
        }
        overflow: hidden;
        border: 1px solid transparent;
        border-radius: 90%;

        &.languageIcon--pl {
          background-image: url("/images/icon-language-pl.svg");
        }
        &.languageIcon--en {
          background-image: url("/images/icon-language-en.svg");
        }
        &.languageIcon--de {
          background-image: url("/images/icon-language-de.svg");
        }
        &.languageIcon--es {
          background-image: url("/images/icon-language-es.svg");
        }
        &.languageIcon--uk {
          background-image: url("/images/icon-language-uk.svg");
        }
        &.languageIcon--fr {
          background-image: url("/images/icon-language-fr.svg");
        }
      }

      &.active {
        .languageIcon {
          border-color: #556dff;
        }
      }

      &:hover {
        .languageIcon {
          transform: scale(1.1);
        }
      }
    }
  }

  .userMenu {
    display: none;
    background-color: rgba(255, 255, 255, 1);
    box-shadow: 0 2px 22px rgba(0, 0, 0, 0.066);
    position: absolute;
    top: 100px;
    border-radius: 4px;
    z-index: 4;

    &.userMenu--opened {
      display: block;
    }

    & > .userMenu__item {
      position: relative;
      overflow: hidden;

      &::before {
        position: absolute;
        content: "";
        width: calc(100% - 40px);
        height: 1px;
        top: auto;
        bottom: 0;
        left: 20px;
        background-color: #e0e0e0;
        transition: all 0.1s ease-in;
      }

      &:first-child {
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
      }

      &:last-child {
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;

        &::before {
          background-color: transparent;
        }
      }

      a {
        padding: 18px 20px;
        display: block;
        font-size: 14px;
        line-height: 22px;
        color: #061c2f;
        font-weight: 100;
        text-decoration: none;
        display: flex;
        align-items: center;
        &:hover {
          background-color: rgba(224, 224, 224, 0.2);
        }

        &.logout {
          font-weight: 400;
        }

        &.active {
          font-weight: 500;
          color: #556dff;
        }
        .language {
          margin-left: 10px;
        }
      }
    }
  }
  .loggedUserAnchor {
    @extend .truncateText;
    color: #ffffff;
    display: inline-block;
    max-width: 94px;
    overflow: hidden;
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    display: inline-block;
    padding: 23px 22px 23px 20px;
    position: relative;
    cursor: pointer;
    margin-right: 10px;

    &::before {
      transition: all 0.2s ease-in;
      content: "";
      width: 13px;
      height: 13px;
      display: block;
      position: absolute;
      background: {
        image: url("/images/icon-arrow-select-down.png");
        position: right center;
        repeat: no-repeat;
      }
      z-index: 2;
      right: 0;
      top: 26px;
    }

    &.loggedUserAnchor--opened {
      &::before {
        top: 30px;
        transform: rotate(-180deg);
      }
    }
  }
}

.header {
  @extend .unselectable;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 70px;
  background: #023562 0% 0% no-repeat padding-box;
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  transform: translateZ(0);

  .userMenuBackground {
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 3;
    overflow: hidden;
    display: none;

    &.userMenuBackground--opened {
      display: block;
    }
  }

  .header__container {
    margin: 0 auto;
    padding: 0 55px;
    max-width: calc(100% - 110px);
    width: 1088px;
    display: flex;
    justify-content: space-between;
    height: 100%;

    .header-content {
      display: flex;
      align-items: center;
    }

    .loggedUserAnchor {
      @extend .truncateText;
      color: #ffffff;
      display: inline-block;
      max-width: 94px;
      overflow: hidden;
      font-size: 14px;
      font-weight: 500;
      line-height: 24px;
      display: inline-block;
      padding: 23px 22px 23px 20px;
      position: relative;
      cursor: pointer;
      margin-right: 10px;

      &::before {
        transition: all 0.2s ease-in;
        content: "";
        width: 13px;
        height: 13px;
        display: block;
        position: absolute;
        background: {
          image: url("/images/icon-arrow-select-down.png");
          position: right center;
          repeat: no-repeat;
        }
        z-index: 2;
        right: 0;
        top: 26px;
      }

      &.loggedUserAnchor--opened {
        &::before {
          top: 30px;
          transform: rotate(-180deg);
        }
      }
    }

    .loggedUserMenuWrapper {
      position: relative;

      .languageIcon {
        transition: all 0.2s ease-in;
        width: 24px;
        height: 24px;
        display: inline-block;
        background: {
          repeat: no-repeat;
        }
        overflow: hidden;
        border: 1px solid transparent;
        border-radius: 90%;

        &.languageIcon--pl {
          background-image: url("/images/icon-language-pl.svg");
        }
        &.languageIcon--en {
          background-image: url("/images/icon-language-en.svg");
        }
        &.languageIcon--de {
          background-image: url("/images/icon-language-de.svg");
        }
        &.languageIcon--es {
          background-image: url("/images/icon-language-es.svg");
        }
        &.languageIcon--uk {
          background-image: url("/images/icon-language-uk.svg");
        }
        &.languageIcon--fr {
          background-image: url("/images/icon-language-fr.svg");
        }
      }

      &.active {
        .languageIcon {
          border-color: #556dff;
        }
      }

      &:hover {
        .languageIcon {
          transform: scale(1.1);
        }
      }

      .userMenu {
        display: none;
        background-color: rgba(255, 255, 255, 1);
        box-shadow: 0 2px 22px rgba(0, 0, 0, 0.066);
        position: absolute;
        top: 59px;
        width: 200px;
        border-radius: 4px;
        left: 19px;
        z-index: 4;

        &.userMenu--opened {
          display: block;
        }

        & > .userMenu__item {
          position: relative;
          overflow: hidden;

          &::before {
            position: absolute;
            content: "";
            width: calc(100% - 40px);
            height: 1px;
            top: auto;
            bottom: 0;
            left: 20px;
            background-color: #e0e0e0;
            transition: all 0.1s ease-in;
          }

          &:first-child {
            border-top-left-radius: 4px;
            border-top-right-radius: 4px;
          }

          &:last-child {
            border-bottom-left-radius: 4px;
            border-bottom-right-radius: 4px;

            &::before {
              background-color: transparent;
            }
          }

          a {
            padding: 10px 20px;
            display: block;
            font-size: 14px;
            line-height: 22px;
            color: #061c2f;
            font-weight: 100;
            text-decoration: none;
            display: flex;
            align-items: center;
            &:hover {
              background-color: rgba(224, 224, 224, 0.2);
            }

            &.logout {
              font-weight: 400;
            }

            &.active {
              font-weight: 500;
              color: #556dff;
            }
            .language {
              margin-left: 10px;
            }
          }
        }
      }
    }

    .headerButton {
      font-size: 14px;
      transition: all 0.2s;
      height: 20px;
      line-height: 20px;
      padding: 10px 30px;
      top: 14px;
      position: relative;
      border: 1px solid #ffffff;
      border-radius: 200px;
      color: #ffffff;
      margin: 0 25px 0 15px;
      display: inline-block;
      text-decoration: none;
      white-space: nowrap;

      &:hover {
        background-color: #ffffff;
        color: #023562;
      }
    }

    .headerItem {
      display: inline-block;
      vertical-align: middle;
      .covidButton {
        font-weight: normal;
        max-width: 200px;
      }
      img {
        transition: all 0.2s ease-in-out;
        margin: {
          top: 23px;
          bottom: 23px;
        }
      }

      a {
        img {
          &:hover {
            transform: scale(1.05);
          }
        }
      }

      .MSButton {
        margin: {
          top: 11px;
          bottom: 11px;
        }
      }

      &.headerItem--userPanel {
        padding-top: 14px;
        display: none;

        .userPanelIcon {
          position: relative;
          transition: 0.3s ease-in-out;
          margin-left: 10px;
          display: inline-block;
          width: 41px;
          height: 41px;
          border-radius: 4px;
          background: {
            color: #061c2f;
            repeat: no-repeat;
            position: center;
            image: url("/images/icon-user-white.svg");
          }
          cursor: pointer;

          canvas {
            color: rgba(255, 255, 255, 0.4);
          }

          &.userPanelIcon--close {
            background-image: url("/images/icon-x-white.svg");
          }
        }
      }

      &.headerItem--hamburgerMenu {
        display: none;
        padding-top: 14px;

        .hamburgerMenuIcon {
          position: relative;
          transition: 0.3s ease-in-out;
          margin-left: 25px;
          display: inline-block;
          width: 41px;
          height: 41px;
          border-radius: 4px;
          background: {
            color: #556dff;
            repeat: no-repeat;
            position: center;
            image: url("/images/icon-hamburger.svg");
          }
          cursor: pointer;

          &.hamburgerMenuIcon--close {
            background-image: url("/images/icon-x-white.svg");
          }
        }
      }

      &.headerItem--mainMenu {
        margin-left: auto;
      }

      &.headerItem--languages {
        padding-top: 21px;
        display: none;
        & > .anchor {
          display: inline-block;
          cursor: pointer;
          margin-right: 2px;

          .languageIcon {
            transition: all 0.2s ease-in;
            width: 24px;
            height: 24px;
            display: inline-block;
            background: {
              repeat: no-repeat;
            }
            overflow: hidden;
            border: 1px solid transparent;
            border-radius: 90%;

            &.languageIcon--pl {
              background-image: url("/images/icon-language-pl.svg");
            }
            &.languageIcon--en {
              background-image: url("/images/icon-language-en.svg");
            }
            &.languageIcon--de {
              background-image: url("/images/icon-language-de.svg");
            }
            &.languageIcon--es {
              background-image: url("/images/icon-language-es.svg");
            }
            &.languageIcon--uk {
              background-image: url("/images/icon-language-uk.svg");
            }
            &.languageIcon--fr {
              background-image: url("/images/icon-language-fr.svg");
            }
          }

          &.active {
            .languageIcon {
              border-color: #556dff;
            }
          }

          &:hover {
            .languageIcon {
              transform: scale(1.1);
            }
          }
        }
      }
      &.headerItem--language {
        padding-top: 21px;
        display: none;

        & > .anchor {
          display: inline-block;
          cursor: pointer;
          margin-right: 2px;

          .languageIcon {
            transition: all 0.2s ease-in;
            width: 24px;
            height: 24px;
            display: inline-block;
            background: {
              repeat: no-repeat;
            }
            overflow: hidden;
            border: 1px solid transparent;
            border-radius: 90%;

            &.languageIcon--pl {
              background-image: url("/images/icon-language-pl.svg");
            }
            &.languageIcon--en {
              background-image: url("/images/icon-language-en.svg");
            }
            &.languageIcon--de {
              background-image: url("/images/icon-language-de.svg");
            }
            &.languageIcon--es {
              background-image: url("/images/icon-language-es.svg");
            }
            &.languageIcon--uk {
              background-image: url("/images/icon-language-uk.svg");
            }
            &.languageIcon--fr {
              background-image: url("/images/icon-language-fr.svg");
            }
          }

          &.active {
            .languageIcon {
              border-color: #556dff;
            }
          }

          &:hover {
            .languageIcon {
              transform: scale(1.1);
            }
          }
        }
      }
    }

    .mainMenu {
      display: flex;
      padding: 0;
      margin: 0;
      height: 100%;
      max-height: 70px;

      .mainMenu__item {
        &:first-child a {
          font-weight: 700;
        }
        display: inline-block;
        height: 100%;

        a {
          transition: all 0.2s ease-in;
          position: relative;
          color: #ffffff;
          text-decoration: none;
          font-size: 14px;
          font-weight: 100;
          line-height: 24px;
          display: inline-block;
          padding: 23px 10px 23px 10px;

          &::before {
            transition: bottom 0.2s ease-in;
            content: "";
            position: absolute;
            top: auto;
            left: 5px;
            bottom: -4px;
            width: calc(100% - 10px);
            height: 4px;
            background-color: transparent;
            border-radius: 200px;
          }

          &:hover {
            //background: rgba(0,11,20,0.14);
            background: linear-gradient(
              180deg,
              rgba(2, 53, 98, 0.14) 0%,
              rgba(0, 11, 20, 0.14) 100%
            );
          }

          &.active {
            &::before {
              background-color: unset;
              bottom: 0;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1090px) {
  .mobileMenu,
  .mobileUserMenu {
    display: block;
  }

  .mobileMenu {
    &.mobileMenu--show {
      display: block;
    }
  }

  .header {
    .header__container {
      .loggedUserAnchor {
        max-width: 320px;
      }
      .headerItem {
        &.headerItem--mainMenu {
          display: none;
        }

        &.headerItem--language {
          display: inline-block;
          margin-left: auto;
        }

        &.headerItem--button {
          margin-left: auto;

          &.menuLanguage {
            display: none;
          }
        }

        &.headerItem--hamburgerMenu {
          display: inline-block;
        }
      }
    }
  }
}

@media only screen and (max-width: 990px) {
  .header {
    .header__container {
      .loggedUserAnchor {
        max-width: 150px;
      }
      padding: 0 20px;
      max-width: calc(100% - 40px);

      .loggedUserAnchor {
        margin-right: 25px;
      }

      .headerItem {
        .covidButton {
          display: none;
        }

        &.headerItem--language {
          display: inline-block;
          margin-left: auto;
        }

        &.headerItem--mainMenu {
          display: none;
        }

        &.headerItem--button {
          margin-left: auto;
        }

        &.headerItem--hamburgerMenu {
          display: inline-block;
        }
      }
    }
  }
}

@media only screen and (max-width: 540px) {
  .header {
    .header__container {
      .headerItem {
        &.headerItem--button,
        &.headerItem--languages {
          display: none;
        }
        &.headerItem--language {
          display: inline-block;
          margin-left: auto;
        }
        &.headerItem--userPanel {
          display: inline-block;
        }

        &.headerItem--hamburgerMenu {
          display: inline-block;
          margin-left: 0;

          .hamburgerMenuIcon {
            margin-left: 10px;
          }
        }
      }
    }
  }
  .plugin-mode {
    .restaurantHeader {
      display: flex;
      gap: 15px;
    }
  }
}
