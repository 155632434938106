@import "../../styles/helpers/text.scss";

.footer {
  @extend .unselectable;
  top: 0px;
  left: 0px;
  width: 100%;
  background: #061c2f;

  .additionalFooterLogoContainer {
    display: none;
    text-align: center;
    padding: 30px 0 0 0;

    img {
      opacity: 0.3;
      transition: all 0.2s ease-in-out;
    }
  }

  .footer__container {
    margin: 0 auto;
    padding: {
      top: 38px;
      bottom: 76px;
    }
    max-width: 1088px;
    display: flex;

    &--plugin {
      justify-content: flex-end;
      padding: {
        top: 8px;
        bottom: 8px;
      }
    }

    .footerContent--plugin {
      color: #ffffff;
      align-items: center;
      display: flex;
      padding: 10px 6px 5px 10px;
      img {
        transition: all 0.2s ease-in-out;
        margin-left: 0px;
      }

      a {
        img {
          transform: scale(0.8);
          &:hover {
            transform: scale(0.85);
          }
        }
      }
      h3 {
        font-weight: 400;
        font-size: 70%;
        margin-bottom: -1px;
        margin-top: 0;
      }
    }

    .footerLogo {
      width: 142px;
      opacity: 0.3;
      transition: all 0.2s ease-in-out;
    }

    .googlePlayImage {
      display: block;
      width: 139px;
      height: 92px;
      padding: 5px 0 0 33px;
    }

    .googlePlay,
    .appStore {
      text-decoration: none;
      display: block;
      margin: 0 auto 5px 35px;
      width: 125px;
      height: 38px;
      background: {
        image: url("/images/google_play-app_store.svg");
        repeat: no-repeat;
        position: -7px -5px;
      }
      cursor: pointer;
      transition: all 0.2s ease-in;

      &:hover {
        transform: scale(1.05);
      }
    }
    .googlePlay {
      margin-top: 16px;
    }
    .appStore {
      background-position: -7px -48px;
    }

    a {
      .footerLogo {
        &:hover {
          opacity: 0.6;
        }
      }
    }

    .footerItem {
      display: inline-block;
      vertical-align: middle;

      &.footerItem--logo {
        display: inline-block;
      }
      &.footerItem--footerMenu {
        margin-left: auto;
      }
    }

    .footerMenu {
      display: block;
      padding: 0;
      margin: 0;
      height: 100%;

      .footerMenu__item {
        display: inline-block;
        height: 100%;
        vertical-align: top;

        .headerItem {
          transition: all 0.2s ease-in;
          position: relative;
          color: #ffffff;
          text-decoration: none;
          font-size: 12px;
          font-weight: 500;
          text-transform: uppercase;
          text-wrap: wrap;
          line-height: 24px;
          display: inline-block;
          padding: 5px 35px 0 35px;
          letter-spacing: 1px;
          margin: 0;
          max-width: 120px;
        }

        .submenuItems {
          display: block;
          padding: 0;
          margin: 10px 0 0 0;
          padding: 0 35px 0 35px;

          .submenuItems__item {
            display: block;
            margin-bottom: 3px;

            a {
              transition: all 0.2s ease-in;
              position: relative;
              color: rgba(255, 255, 255, 0.7);
              text-decoration: none;
              font-size: 12px;
              font-weight: 100;
              line-height: 24px;
              display: inline-block;

              &:hover {
                color: rgba(255, 255, 255, 1);
              }
            }
          }
        }
      }
    }
  }
  @media only screen and (max-width: 1214px) {
    .additionalFooterLogoContainer {
      display: block;

      .downloadLinks {
        display: none;
      }
    }
    .footer__container {
      padding-top: 25px;
      padding-bottom: 40px;

      &--plugin {
        justify-content: flex-end;
        padding: {
          top: 8px;
          bottom: 8px;
        }
      }

      .footerContent--plugin {
        color: #ffffff;
        align-items: center;
        img {
          transition: all 0.2s ease-in-out;
          margin-left: 0px;
        }

        a {
          img {
            transform: scale(0.8);
            &:hover {
              transform: scale(0.85);
            }
          }
        }
        h3 {
          font-weight: 400;
          font-size: 70%;
        }
      }

      .googlePlay,
      .appStore {
        margin: 10px auto 0 auto;
      }

      .footerItem {
        &.footerItem--logo {
          display: none;
        }
        &.footerItem--footerMenu {
          margin-left: auto;
          margin-right: auto;
          text-align: center;
        }
      }
    }
  }
  @media only screen and (max-width: 1090px) {
    .footer__container {
      max-width: calc(100% - 110px);
      width: 1088px;
      padding-left: 55px;
      padding-right: 55px;

      &--plugin {
        justify-content: flex-end;
        padding: {
          top: 8px;
          bottom: 8px;
        }
      }

      .footerContent--plugin {
        color: #ffffff;
        align-items: flex-end;
        img {
          transition: all 0.2s ease-in-out;
          margin-left: 8px;
        }

        a {
          img {
            &:hover {
              transform: scale(1.05);
            }
          }
        }
        h3 {
          font-weight: 400;
          font-size: 90%;
        }
      }
    }
  }
  @media only screen and (max-width: 990px) {
    .footer__container {
      max-width: calc(100% - 40px);
      padding-left: 20px;
      padding-right: 20px;

      &--plugin {
        justify-content: flex-end;
        padding: {
          top: 0px;
          bottom: 0px;
        }
      }

      .footerContent--plugin {
        color: #ffffff;
        align-items: center;
        img {
          transition: all 0.2s ease-in-out;
          margin-left: 0px;
        }

        a {
          img {
            transform: scale(0.8);
            &:hover {
              transform: scale(0.85);
            }
          }
        }
        h3 {
          font-weight: 400;
          font-size: 70%;
        }
      }

      .footerMenu .footerMenu__item {
        width: 25%;
        margin-bottom: 20px;
        height: auto;
      }
    }
  }
  @media only screen and (max-width: 890px) {
    .footer__container {
      &--plugin {
        justify-content: flex-end;
        padding: {
          top: 8px;
          bottom: 8px;
        }
      }

      .footerContent--plugin {
        color: #ffffff;
        align-items: center;
        img {
          transition: all 0.2s ease-in-out;
          margin-left: 0px;
        }

        a {
          img {
            transform: scale(0.8);
            &:hover {
              transform: scale(0.85);
            }
          }
        }
        h3 {
          font-weight: 400;
          font-size: 70%;
        }
      }
      .footerMenu .footerMenu__item {
        width: 33%;
      }
    }
  }
  @media only screen and (max-width: 720px) {
    .footer__container {
      &--plugin {
        justify-content: flex-end;
        padding: {
          top: 8px;
          bottom: 8px;
        }
      }

      .footerContent--plugin {
        color: #ffffff;
        align-items: center;
        img {
          transition: all 0.2s ease-in-out;
          margin-left: 0px;
        }

        a {
          img {
            transform: scale(0.8);
            &:hover {
              transform: scale(0.85);
            }
          }
        }
        h3 {
          font-weight: 400;
          font-size: 70%;
        }
      }
      .footerMenu .footerMenu__item {
        width: 50%;
      }
    }
  }
  @media only screen and (max-width: 380px) {
    .additionalFooterLogoContainer {
      padding: 30px 0 30px 0;

      .downloadLinks {
        display: block;
        margin-top: 30px;

        .googlePlay,
        .appStore {
          display: block;
          margin: 0 auto 5px auto;
          width: 125px;
          height: 38px;
          background: {
            image: url("/images/google_play-app_store.svg");
            repeat: no-repeat;
            position: -7px -5px;
          }
          cursor: pointer;
          transition: all 0.2s ease-in;

          &:hover {
            transform: scale(1.05);
          }
        }

        .appStore {
          background-position: -7px -48px;
        }
      }
    }

    .footer__container {
      &--plugin {
        justify-content: flex-end;
        padding: {
          top: 8px;
          bottom: 8px;
        }
      }

      .footerContent--plugin {
        color: #ffffff;
        align-items: center;
        img {
          transition: all 0.2s ease-in-out;
          margin-left: 0px;
        }

        a {
          img {
            transform: scale(0.8);
            &:hover {
              transform: scale(0.85);
            }
          }
        }
        h3 {
          font-weight: 400;
          font-size: 70%;
        }
      }

      .footerMenu .footerMenu__item {
        width: 100%;
        text-align: center;
      }

      .googlePlay,
      .appStore {
        margin: 0 auto 5px auto;
      }

      .googlePlay {
        margin-top: 16px;
      }
    }
  }
}
