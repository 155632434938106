.ratingStars {
  display: block;

  & > span > span {
    margin-left: 2px;

    &:first-child {
      margin-left: 0;
    }
  }
}
