.notFoundPageContainer {
  .narrowerPageContainer__inside {
    display: block;
  }

  .notFoundPageContainer__inside {
    &.notFoundPageContainer__inside--minusMargin {
      margin-top: -200px;
      position: relative;
    }

    a {
      color: #061c2f;
      text-decoration: none;
      border-bottom: 1px solid rgba(6, 28, 47, 0.2);

      &:hover {
        border-bottom: 1px solid #061c2f;
      }
    }
  }

  .backgroundColorSpace {
    width: 100%;
    background-color: #061c2f;
    display: block;
    height: 210px;
  }

  .whiteBox {
    background-color: #ffffff;
    box-shadow: 0 0 15px rgba(2, 53, 98, 0.04);
    padding: 54px 64px 54px 64px;
    border-radius: 4px;
    display: block;
  }

  h3 {
    font-size: 22px;
    line-height: 27px;
    color: #061c2f;
    font-weight: 500;
    margin: 30px 0 15px 0;
    text-align: center;

    strong {
      font-weight: 500;
    }
  }

  p {
    font-size: 14px;
    line-height: 19px;
    color: #061c2f;
    font-weight: 100;
    margin: 15px 0 0 0;
    text-align: center;
  }

  .alertPictogram {
    width: 86px;
    height: 86px;
    background: {
      image: url("/images/icon-alert.svg");
      repeat: no-repeat;
    }
    margin: 0 auto;
    display: block;
  }

  .button-container {
    margin: 40px 0 0 0;
    text-align: center;
  }

  @media only screen and (max-width: 990px) {
    .notFoundPageContainer__inside {
      .whiteBox {
        margin-left: 0 !important;
        border-radius: 4px !important;
        margin-right: 0 !important;
      }
    }
  }
}
