@import "../../styles/helpers/text.scss";

.pagination {
  @extend .unselectable;
  text-align: center;
  display: block;

  .pagination__item {
    transition: all 0.2s ease-in;
    display: inline-block;
    border: 1px solid transparent;
    border-radius: 90%;
    font-size: 12px;
    color: #061c2f;
    font-weight: 400;
    line-height: 28px;
    width: 28px;
    height: 28px;
    text-align: center;
    font-size: 13px;
    font-weight: 100;
    cursor: pointer;
    vertical-align: middle;
    margin: 0 5px;

    &:hover {
      background-color: rgba(85, 109, 255, 0.1);
    }

    &:active {
      border-color: #556dff;
    }

    &.pagination__item--active {
      font-weight: 500;
      color: #556dff;
      border-color: #556dff;
    }

    &.pagination__item--next,
    &.pagination__item--previous {
      background: {
        repeat: no-repeat;
        position: center;
      }
    }

    &.pagination__item--next {
      background-image: url("/images/icon-pagination-right-arrow.svg");
      background-position: 6px center;
    }

    &.pagination__item--previous {
      background-image: url("/images/icon-pagination-left-arrow.svg");
      background-position: 10px center;
    }
  }
}
