.ReactModalPortal .ReactModal__Overlay .ReactModal__Content {
  &.eventModal {
    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    transform: translate(-50%, -50%);
    padding: 0px !important;
    outline: none !important;
    display: inline-block !important;
    width: 70vh !important;
    background-color: transparent !important;

    .closeModalTextButton {
      transition: all 0.2s ease-in;
      position: absolute;
      top: -22px;
      right: 0;
      font-size: 13px;
      color: #061c2f;
      cursor: pointer;
    }
  }
}
