.ReactModalPortal {
  z-index: 99999;
  position: absolute;

  .ReactModal__Overlay {
    background-color: rgba(244, 244, 244, 0.9) !important;

    .ReactModal__Content {
      border: 0 !important;
      background-color: #fff !important;
      border-radius: 5px !important;
      max-width: 80% !important;
      box-shadow: 0 2px 10px rgba(2, 53, 98, 0.04);
      position: relative !important;
      padding: 35px 55px 35px 30px !important;
      box-sizing: border-box;
      max-height: 90vh;
      width: auto;
      display: inline-block;

      .closeModalButton {
        display: block;
        width: 14px;
        height: 14px;
        background: {
          image: url("/images/icon-close-modal.svg");
          repeat: no-repeat;
          position: center;
          size: 16px;
        }
        padding: 4px;
        position: absolute;
        right: 20px;
        top: 20px;
        z-index: 2;
        cursor: pointer;
      }
    }
  }
}

@media only screen and (max-width: 736px) and (max-height: 736px) {
  .ReactModalPortal {
    .ReactModal__Overlay {
      .ReactModal__Content {
        max-width: 100% !important;
        max-height: 90vh !important;
        padding: 25px 20px 20px 20px !important;
      }
    }
  }
}
