@import "../../styles/helpers/text.scss";

.horizontalScrollMenuAlternativeContainer {
  font-family: "Rubik", sans-serif !important;
  font-size: 14px !important;
  display: flex;
  align-items: center;

  .horizontalScrollbar__previous,
  .horizontalScrollbar__next {
    display: flex;
    transition: all 0.2s ease-in-out;
    color: transparent;
    cursor: pointer;
    padding: 10px 0 10px 5px;
    color: rgba(0, 0, 0, 1);
    align-items: center;
  }

  .horizontalScrollbar__previous {
    padding: 10px 5px 10px 0;
  }

  .horizontalScrollbar__content {
    display: inline-block;
    width: calc(100% - 38px);
  }

  .hourSlider {
    .slick-dots {
      display: none !important;
    }

    .slick-next,
    .slick-prev {
      display: none !important;
    }

    .slick-list {
      padding: 0;
    }

    .slick-slide {
      margin-right: 15px !important;

      .horizontalScrollbar__content-item {
        position: relative;
        cursor: pointer;
        transition: all 0.2s ease-in-out;
        line-height: 35px;
        padding: 5px;
        border: 1px solid #e0e0e0;
        display: inline-block;
        text-align: center;
        font-size: 15px;
        border-radius: 4px;
        font-size: 400;
        box-sizing: border-box;
        min-width: 65px;
        color: rgba(6, 28, 47, 1);

        &:hover {
          background-color: rgba(253, 106, 59, 0.08);
          border: 1px solid rgba(253, 106, 59, 0.08);
        }

        &.horizontalScrollbar__content-item--active {
          color: #fd6a3b !important;
          border-color: #fd6a3b !important;
          // background-color: rgba(253, 106, 59, 0.05);
        }

        &.horizontalScrollbar__content-item--disabled {
          // filter: grayscale(100%);
          pointer-events: none;
          // opacity: 0.6;
          cursor: default;
          color: rgba(6, 28, 47, 0.2);
        }
      }
    }
  }

  // @extend .unselectable;
  // position: relative !important;
  // display: flex;

  // .horizontalScrollbar__previous,
  // .horizontalScrollbar__next {
  //   display: inline-block;
  //   width: 9px;
  //   transition: all 0.2s ease-in-out;
  //   color: transparent;
  //   cursor: pointer;
  //   padding: 10px 0 10px 10px;

  //   &::after {
  //     content: '❯';
  //     display: block;
  //     transition: all 0.2s ease-in-out;
  //     position: absolute;
  //     color: rgba(0, 0, 0, 1);
  //   }

  //   &:hover {
  //     &::after {
  //       color: rgba(0, 0, 0, 1);
  //     }
  //   }
  // }

  // .horizontalScrollbar__previous {
  //   padding: 10px 10px 10px 0;
  //   &::after {
  //     content: '❮';
  //   }
  // }

  // .horizontalScrollbar__content {
  //   display: inline-block;
  //   width: calc(100% - 38px);

  //   .horizontalScrollbar__content-item {
  //     position: relative;
  //     cursor: pointer;
  //     transition: all 0.2s ease-in-out;
  //     line-height: 35px;
  //     min-width: 55px;
  //     padding: 5px;
  //     border: 1px solid #E0E0E0;
  //     display: inline-block;
  //     text-align: center;
  //     font-size: 15px;
  //     border-radius: 4px;
  //     font-size: 400;
  //     margin-right: 15px;

  //     &.horizontalScrollbar__content-item--disabled {
  //       filter: grayscale(100%);
  //       //pointer-events: none;
  //       opacity: 0.6;
  //       cursor: default;
  //     }
  //   }
  // }
}
