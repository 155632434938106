@import "../../styles/helpers/text.scss";

.restaurantPromotionInfoModal {
  position: relative;

  .restaurantNameAnchor {
    text-decoration: none;
    color: #061c2f;
  }

  .logoAnchor {
    border: 0;
  }

  .loaderMask--restaurantLogo {
    width: 70px;
    height: 70px;
    background-color: #fff;
    overflow: hidden;
    position: absolute;
    right: 0;
    top: 0;
  }

  .restaurantLogo {
    margin: 0;
    max-width: 140px;
    max-height: 80px;
    object-fit: contain;
    object-position: center;
    display: block;
    position: absolute;
    top: 5px;
    right: 5px;
  }

  h2 {
    font-size: 20px;
    line-height: 24px;
    color: #061c2f;
    font-weight: 400;
    margin: 0;
  }

  .subheader {
    font-size: 14px;
    line-height: 16px;
    color: #ffffff;
    font-weight: 100;
    margin: 10px 0 20px 0;

    .locationValue {
      vertical-align: top;
      display: inline-block;
      color: #061c2f;
      font-size: 13px;
      line-height: 15px;
      font-weight: 100;
      margin: 0;
      padding-left: 18px;
      background: {
        image: url("/images/icon-location.svg");
        repeat: no-repeat;
      }
      padding-right: 30px;
    }

    .hoursValue {
      vertical-align: top;
      display: inline-block;
      color: #061c2f;
      font-size: 13px;
      line-height: 15px;
      font-weight: 100;
      margin: 0;
      padding-left: 18px;
      background: {
        image: url("/images/icon-clock.svg");
        repeat: no-repeat;
      }
      max-width: calc(100% - 290px);
    }
  }

  .categoryName {
    color: #556dff;
    font-size: 11px;
    line-height: 10px;
    font-weight: 500;
    margin: 0 0 12px 0;
    text-transform: uppercase;
  }

  .restaurantDescription {
    color: #061c2f;
    font-size: 14px;
    line-height: 19px;
    font-weight: 100;

    p {
      margin: 0;
      display: -webkit-box;
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  h2,
  .subheader,
  .restaurantDescription {
    padding-right: 120px;
  }

  .promotionDescription {
    margin-top: 26px;
    color: #061c2f;

    h3 {
      font-size: 18px;
      line-height: 23px;
      font-weight: 500;
    }

    p {
      font-size: 14px;
      line-height: 19px;
      font-weight: 100;
      margin: 0 0 10px 0;
      img {
        width: 100%;
      }
    }
  }

  .button-container {
    display: flex;
    justify-content: space-between;
  }

  .MSButton {
    margin-top: 30px;
    display: flex;
    align-items: center;
    max-width: 200px;
    justify-content: center;
  }
}

@media only screen and (max-width: 767px) {
  .restaurantPromotionInfoModal {
    .subheader .hoursValue {
      width: 100%;
      max-width: 100%;
      margin: 8px 0 0 0;
    }
  }
}

@media only screen and (max-width: 600px) {
  .restaurantPromotionInfoModal {
    h2,
    .subheader,
    .restaurantDescription {
      padding-right: 0;
    }

    .loaderMask--restaurantLogo,
    .restaurantLogo {
      display: none;
    }
    .subheader .hoursValue {
      max-width: 100%;
      margin: 8px 0 0 0;
    }
    .button-container {
      flex-wrap: wrap;
      a {
        width: 100%;
        min-width: 50px;
      }
    }
  }
}
