@import "./helpers/variables.scss";

.formContainer {
  .row {
    display: flex;
    flex-direction: row;
    flex: 1;
  }
  .column {
    flex-direction: column;
    padding-right: 50px;
    flex: 1;
    position: relative;

    &--reservations {
      margin-left: 8px;
    }
  }

  .columnDiscount {
    flex-direction: column;
    padding-right: 8px;
    flex: 1;
    position: relative;
  }

  .columnPDF {
    flex-direction: column;
    flex: 1;
    position: relative;
    height: 500px;
    border: 1px solid $color-grey-light;
  }

  .column:last-child {
    padding-right: 0;
  }

  .select {
    display: block;
    margin-bottom: 18px;
    min-height: 30px;
    max-width: 100% !important;
    transition: all 0.2s ease-in;
    border: 1px solid #e0e0e0;
    height: 55px;
    padding: 18px 13px;
    font-size: 15px;
    font-weight: 500;
    line-height: 18px;
    color: #061c2f;
    width: 100%;
    border-radius: 3px;
    outline: none;
    font-weight: 400;
    font-family: "Rubik", sans-serif;
    box-sizing: border-box;
    -webkit-appearance: none;
    background: url(../images/down-arrow-svgrepo-com.svg);
    background-repeat: no-repeat;
    background-position-x: 98%;
    background-position-y: 50%;
    cursor: pointer;
  }

  .formItem {
    display: block;
    margin-bottom: 18px;
    min-height: 30px;
    max-width: 100% !important;
    position: relative;

    .formError {
      font-weight: 400;
      font-size: 12px;
      text-align: left;
      position: absolute;
      bottom: -16px;
      left: 2px;
      color: #ed0023;
      opacity: 0;
      transform: translate(0, 0);
      animation: errorMoveIn 0.2s ease-out;
      animation-fill-mode: forwards;
    }

    .labelValue {
      color: #061c2f;
      font-size: 15px;
      line-height: 18px;
      margin: 0;
      font-weight: 400;
    }

    .label {
      font-size: 13px;
      line-height: 15px;
      text-align: left;
      margin: 0 0 7px 0;
      color: #767676;
      text-transform: uppercase;

      .requiredField {
        color: #ed0023;
      }
    }
    .checkboxContainer {
      &.checkboxContainer--error {
        .aka-label {
          //color: #ED0023 !important;
          &::before {
            border-color: #ed0023 !important;
          }
        }
      }
    }
    .input {
      /* transition: all 0.2s ease-in; */
      border: 1px solid #e0e0e0;
      height: 55px;
      padding: 18px 13px;
      font-size: 15px;
      font-weight: 500;
      line-height: 18px;
      color: #061c2f;
      width: 100%;
      border-radius: 3px;
      outline: none;
      font-weight: 400;
      font-family: "Rubik", sans-serif;
      box-sizing: border-box;

      &.input--table {
        height: 30px;
        font-size: 14px;
        width: 45px;
        padding: 10px;
        margin: 0px 5px;
        text-align: center;
      }

      &.input--textArea {
        min-height: 92px;
        resize: none;
      }

      &.input--error {
        border-color: #ed0023;
      }

      &.input--success {
        border-color: #22aa0a;
      }

      &::placeholder {
        /* Chrome, Firefox, Opera, Safari 10.1+ */
        font-family: "Rubik", sans-serif;
        font-weight: 100 !important;
        color: #767676;
        opacity: 0.5;
      }

      &:-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        font-family: "Rubik", sans-serif;
        font-weight: 100 !important;
        color: #767676;
        opacity: 0.5;
      }

      &::-ms-input-placeholder {
        /* Microsoft Edge */
        font-family: "Rubik", sans-serif;
        font-weight: 100 !important;
        color: #767676;
        opacity: 0.5;
      }
    }
  }

  .formItems {
    display: flex;
    justify-content: normal; //space-between;
    align-items: center;

    & > .formItem {
      display: inline-block;
      margin-left: 15px;

      &:first-child {
        margin-left: 0;
      }
    }

    &.formItems--inline {
      display: block;

      .formItem {
        min-width: 250px;
        max-width: 100%;
      }
    }
  }

  @media only screen and (max-width: 560px) {
    .formItems {
      &.formItems--inline {
        .formItem {
          min-width: none;
          width: 100%;
          max-width: 100%;
          margin-left: 0;
        }
      }
      &.date-section {
        flex-wrap: wrap;
        justify-content: space-between;
        .formItem.formItem--date,
        .formItem.formItem--persons {
          width: 45%;
          margin-left: 0;
        }
        .formItem.formItem--persons.button-closest-time {
          width: 100%;
          justify-content: flex-end;
          display: flex;
          button {
            margin-top: 0 !important;
          }
        }
      }
    }
  }
}

@keyframes errorMoveIn {
  0% {
    transform: translate(0, -40%);
    opacity: 0;
  }

  100% {
    transform: translate(0, 0);
    opacity: 1;
  }
}
