@import "./keyframes.scss";

.loaderMask {
  display: block;
  width: 100%;
  height: 120px;
  background-color: #fafdff;
  overflow: hidden;

  .loaderMaskInside {
    animation: rotating 0.8s linear infinite;
    display: block;
    background: {
      image: url("/images/loader.svg");
      repeat: no-repeat;
      position: center;
      size: 30px;
    }
    width: 100%;
    height: 100%;
  }
}
