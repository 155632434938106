.unselectable {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.unselectable ::-moz-selection {
  background-color: transparent;
  color: #354052;
}
.unselectable ::selection {
  background-color: transparent;
  color: #354052;
}

.selectable {
  -webkit-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
}

.selectable ::-moz-selection {
  background-color: #556dff;
  color: #ffffff;
}
.selectable ::selection {
  background-color: #556dff;
  color: #ffffff;
}

.truncateText {
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
}
