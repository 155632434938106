.infoBox {
  cursor: default;
  background-color: #fff;
  border-radius: 4px;
  padding: 20px;
  margin: 0 10px 10px 0;
  position: relative;
  overflow: visible !important;
  box-shadow: 0 0 22px rgba(0, 0, 0, 0.1);
  max-width: calc(100% - 40px);
  margin-top: 8px !important;

  z-index: 3;

  &::before {
    content: "";
    width: 16px;
    height: 16px;
    background-color: #fff;
    //box-shadow: 0 0 22px rgba(0, 0, 0, 0.2);
    position: absolute;
    top: -4px;
    left: 100px;
    overflow: visible;
    transform: rotate(45deg);
    z-index: 2;
  }
}

.mapWrapper {
  position: relative;

  * {
    outline: none !important;
  }

  .previewRestaurantPanelMask {
    z-index: 99;
    position: fixed;
    background-color: transparent;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
  }

  .previewRestaurantPanel {
    position: fixed !important;
    z-index: 100;
    bottom: 0;
    top: auto;
    left: 60px;
    right: 60px;
    width: calc(100% - 120px) !important;
    min-height: 0 !important;
    height: auto !important;
    max-height: none; //calc(50vh - 92px) !important;
    box-shadow: 0 0 40px rgba(0, 0, 0, 0.1);

    .restaurantLine {
      padding: 15px;
      box-sizing: border-box;
      background-color: rgba(255, 255, 255, 1);

      &.restaurantLine--small {
        .horizontalScrollMenuContainer .arrow-prev::after {
          left: 4px;
        }
        .availableHoursContainer {
          .availableHoursContainer__body {
            width: calc(100% - 242px);
          }

          .availableHoursContainer__submit {
            width: 222px;

            & > .MSButton {
              display: inline-block;
              width: 100%; //calc(50% - 7.5px);
              margin-right: 0;
              min-width: 0;
              padding-left: 10px;
              padding-right: 10px;

              // &:last-child {
              //   margin-left: 7.5px;
              //   margin-right: 0;
              // }
            }
          }
        }
      }
    }
  }

  .noData {
    font-size: 14px;
    line-height: 20px;
    color: #fd6a3b;

    strong {
      font-weight: 400;
    }
  }

  .loaderMask {
    background-color: transparent;
    //max-height: 40px;
  }

  & > div {
    display: block !important;
    width: 100% !important;
    height: calc(100vh - 184px) !important;
    min-height: 430px !important;
    position: static !important;
  }
  .gm-style .gm-style-iw-t::after {
    opacity: 0;
    pointer-events: none;
  }
  .gm-style .gm-style-iw-c {
    border-radius: 4px !important;
    max-width: 100% !important;
    //width: 770px;
    width: 90%;
    height: 100px;
    opacity: 0;
    pointer-events: none;

    .gm-style-iw-d {
      width: 750px;
      max-width: 100% !important;
    }
  }

  button.gm-ui-hover-effect[title="Close"] {
    top: 5px !important;
    right: 5px !important;

    img {
      width: 18px !important;
      height: 18px !important;
      margin: 6px !important;
    }
  }

  .gm-style .gm-style-iw-t {
    margin: 10px 0 !important;
  }

  .gm-style .controls {
    font-size: 28px;

    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
    box-sizing: border-box;
    border-radius: 2px;
    cursor: pointer;
    font-weight: 300;
    height: 1em;
    margin: 6px;
    text-align: center;
    user-select: none;
    padding: 2px;
    width: 1em;
  }
  .gm-style .controls button {
    border: 0;
    background-color: white;
    color: rgba(0, 0, 0, 0.6);
  }
  .gm-style .controls button:hover {
    color: rgba(0, 0, 0, 0.9);
  }

  .gm-style .controls.zoom-control {
    display: flex;
    flex-direction: column;
    height: auto;
  }
  .gm-style .controls.zoom-control button {
    font: 0.85em Arial;
    margin: 1px;
    padding: 0;
  }

  .gm-style .controls.maptype-control {
    display: flex;
    flex-direction: row;
    width: auto;
  }
  .gm-style .controls.maptype-control button {
    display: inline-block;
    font-size: 0.5em;
    margin: 0 1px;
    padding: 0 6px;
  }
  .gm-style
    .controls.maptype-control.maptype-control-is-map
    .maptype-control-map {
    font-weight: 700;
  }
  .gm-style
    .controls.maptype-control.maptype-control-is-satellite
    .maptype-control-satellite {
    font-weight: 700;
  }

  .gm-style .controls.fullscreen-control button {
    display: block;
    font-size: 1em;
    height: 100%;
    width: 100%;
  }
  .gm-style .controls.fullscreen-control .fullscreen-control-icon {
    border-style: solid;
    height: 0.25em;
    position: absolute;
    width: 0.25em;
  }
  .gm-style
    .controls.fullscreen-control
    .fullscreen-control-icon.fullscreen-control-top-left {
    border-width: 2px 0 0 2px;
    left: 0.1em;
    top: 0.1em;
  }
  .gm-style
    .controls.fullscreen-control.is-fullscreen
    .fullscreen-control-icon.fullscreen-control-top-left {
    border-width: 0 2px 2px 0;
  }
  .gm-style
    .controls.fullscreen-control
    .fullscreen-control-icon.fullscreen-control-top-right {
    border-width: 2px 2px 0 0;
    right: 0.1em;
    top: 0.1em;
  }
  .gm-style
    .controls.fullscreen-control.is-fullscreen
    .fullscreen-control-icon.fullscreen-control-top-right {
    border-width: 0 0 2px 2px;
  }
  .gm-style
    .controls.fullscreen-control
    .fullscreen-control-icon.fullscreen-control-bottom-left {
    border-width: 0 0 2px 2px;
    left: 0.1em;
    bottom: 0.1em;
  }
  .gm-style
    .controls.fullscreen-control.is-fullscreen
    .fullscreen-control-icon.fullscreen-control-bottom-left {
    border-width: 2px 2px 0 0;
  }
  .gm-style
    .controls.fullscreen-control
    .fullscreen-control-icon.fullscreen-control-bottom-right {
    border-width: 0 2px 2px 0;
    right: 0.1em;
    bottom: 0.1em;
  }
  .gm-style
    .controls.fullscreen-control.is-fullscreen
    .fullscreen-control-icon.fullscreen-control-bottom-right {
    border-width: 2px 0 0 2px;
  }
}

@media only screen and (max-width: 990px) {
  .mapWrapper {
    .previewRestaurantPanel {
      left: 5px;
      right: 5px;
      width: calc(100% - 10px) !important;
    }
  }
}

@media only screen and (max-width: 732px) {
  .mapWrapper {
    & > div {
      min-height: calc(100vh - 100px) !important;
    }

    .previewRestaurantPanel {
      left: 5px;
      right: 5px;
      width: calc(100% - 10px) !important;
      max-height: calc(100vh - 75px) !important;

      .restaurantLine {
        &.restaurantLine--small {
          padding: 0;

          .hoursValue {
            padding-right: 80px;
          }

          .aboutRestaurantText {
            display: none;
          }

          .availableHoursContainer {
            .availableHoursContainer__body {
              width: 100%;

              .horizontalScrollMenuContainer .arrow-prev::after {
                left: 0;
              }

              .horizontalScrollMenuContainer
                .menu-wrapper--inner
                > .menu-item-wrapper
                .menu-item {
                line-height: 32px;
                padding: 4px 8px;
                min-width: 0;
                font-size: 14px;
              }
            }

            .availableHoursContainer__submit {
              width: 100%;

              & > .MSButton {
                display: inline-block;
                width: 100%; //calc(50% - 7.5px);
                margin-right: 0;
                min-width: 0;
                padding: 11.75px 20px 11.75px 20px;
              }
            }
          }

          .restaurantLine__details {
            padding-left: 20px;
            width: 100%;
            padding-right: 20px;
            box-sizing: border-box;
            padding-bottom: 15px;

            .restaurantLogo {
              right: 20px;
            }
          }
          .restaurantLine__image {
            display: none;
            width: 100%;
            min-height: 0;

            .restaurantLogo {
              width: 100%;
              height: 50px;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 460px) {
  .infoBox {
    padding: 10px;
  }
}
